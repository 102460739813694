import { Text, Layout, TextField, Subheading, TextContainer, Stack } from "@shopify/polaris";
import { FcHighPriority } from "react-icons/fc";


export default function NumberInputSetting({
    title,
    body,
    val,
    handleChange,
    hint,
    err,
    banner = ""
}) {
    return (
        <Layout>
            <Layout.Section oneHalf>
                <TextContainer>
                <Text variant="headingMd" as="h2">{title}</Text>
                    <p>{body}</p>

                </TextContainer>
            </Layout.Section>

            <Layout.Section oneHalf>
                <TextField
                    min={0}
                    minLength={1}
                    pattern="^[0-9]\d*"
                    requiredIndicator={true}
                    inputMode="numeric"
                    label="Set tree planting limit"
                    type="number"
                    value={val}
                    onChange={handleChange}
                    error={err}
                    helpText={
                        <p>{err ? "" : hint}</p>
                    }

                >
                </TextField>
                <br/>
                {banner}
            </Layout.Section>
        </Layout>
    )


}