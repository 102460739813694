import { GetShopAllowed, PostAdditionalTrees, GetTopupState } from "../backend";
import Crisp from "./Crisp";
import { Frame, Toast, HorizontalStack,LegacyCard ,Text,TextField, ChoiceList,Banner, Button, Layout, Page, Stack, Toast, Frame, ButtonGroup, Form, FormLayout, TextContainer, ProgressBar, Select, Card, Checkbox, Divider } from "@shopify/polaris"
import React from "react";
import { Redirect} from '@shopify/app-bridge/actions';

import { useAppBridge, } from "@shopify/app-bridge-react"
import { authenticatedFetch,} from '@shopify/app-bridge-utils';
import Metrics from "./metrics";


export default function PlantMoreTrees() {
    const shopify = useAppBridge();
    const httpFetch = authenticatedFetch(shopify);
    const [value, setValue] = React.useState('2');
    const [loading, setLoading] = React.useState(false);
    const handleChange = React.useCallback(
        (newValue) => setValue(newValue),
        [],
      );
    const [toastState, setToastState] = React.useState({
        state: false,
        message: "",
    });
    const redirect = Redirect.create(shopify)
    const toggleToastState = React.useCallback(
        (msg, state) => setToastState({
            state: state,
            message: msg,
        }),
        []
    )



    React.useEffect(() => {
        GetTopupState(httpFetch).then((state) => {
            if (state.status == "active") {
                toggleToastState("success", true)
            } else if (state.status == "declined") {
               toggleToastState("declined", true)
            }
        }
        )
    }, [])

   







  
   async function handleSubmit(){
        setLoading(true)

        var d = await PostAdditionalTrees(httpFetch, parseInt(value))
        if (d == "") {
            alert("Failed")
            setLoading(false)
            return 
        } 


        redirect.dispatch(Redirect.Action.REMOTE, {
            url: d,
        });
        setLoading(false)
    }

    const toastMarkup = toastState.state ? (
        <Frame>
            < Toast content={toastState.message} duration={5000} onDismiss={
                toggleToastState
            } />
        </Frame>
    ) : null;
    return (
        <>
      
        <Crisp />
        <Page fullWidth>
            <Text variant="heading2xl" as="h1">Fund more trees</Text>
            <br/>
            <Divider />
      <Layout sectioned>
        
        <Layout.AnnotatedSection
          id="storeDetails"
          title="Easily plant additional trees"
          description={<>
          
         <Text>Contributions will appear on your monthly bill and your contribution will be registered immediately after checkout</Text><br/>
         <p>purchasing additional trees <strong>does not </strong> impact your monthly tree limit</p>

         </>}
        >
          <LegacyCard sectioned>
            <Layout.Section>
            <TextField
                label="How many trees would you like to fund today?"
                type="number"
                value={value}
                onChange={handleChange}
                autoComplete="off"
                helpText={<>
                    <p>Each tree costs $0.33, a minimum of 2 trees is required</p>
                    </>
                }
                min={2}
            />


              <br/>
              <br/>
              <Text variant="bodySm" as="p"> Order Summary </Text>
              <br/>
              <Divider />
                <br/>
                <HorizontalStack align="space-between">
                <Text variant="bodySm" as="p"> <strong>Total Cost: {value} Trees</strong> </Text>
                {/* Round to 2 decimal places */}
                <Text variant="bodySm" as="p"> <strong> ${Math.round(value * 0.33 * 100) / 100} USD</strong> </Text>
                </HorizontalStack>
                <br/>
               
              <HorizontalStack align="end">
         <Button fullWidth={false} primary onClick={() => {
            async function n() {
                await handleSubmit(value)
            }

            n()
         } } loading={loading}>Fund Trees</Button>
         </HorizontalStack>  
         <br/>

                   </Layout.Section>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
      {toastMarkup}

    </Page>



        </>

    )
}