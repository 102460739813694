import React, { useEffect, useState } from 'react';
import { Text, Badge, Card, ProgressBar, VerticalStack, HorizontalStack, LegacyCard, LegacyStack } from '@shopify/polaris';
import { useAppBridge } from '@shopify/app-bridge-react';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { BASE_URL, isProd, useInterval } from '../backend';

const STATUS_COLORS = {
  inactive: 'critical',
  limit_reached: 'warning',
  active: 'success',
};

const REFRESH_RATE = 10000; // 10s

export default function Metrics() {
  const shopify = useAppBridge();
  const [metrics, setMetrics] = useState({
    totalLifetime: 0,
    totalMonthly: 0,
    freeTrees: 0,
    progressPeriod: 0,
  });
  const [appStatus, setAppStatus] = useState('');
  const [freeAnnotation, setFreeAnnotation] = useState('');

  const httpFetch = isProd() ? authenticatedFetch(shopify) : fetch;

  const updateMetrics = async () => {
    try {
      const requestOptions = {
        crossDomain: true,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const url = `${BASE_URL}/app/metrics`;
      const resp = await httpFetch(url, requestOptions);
      const json = await resp.json();

      // Ensure non-negative trees remaining value
      json.treesRemainingInBudget = Math.max(json.treesRemainingInBudget, 0);

      setMetrics(json);

      if (json.freeTrees > 0) {
        setFreeAnnotation(<p style={{ color: 'grey' }}>{`of which ${json.freeTrees} were free`}</p>);
      } else {
        setFreeAnnotation('');
      }

      setAppStatus(<Badge status={STATUS_COLORS[json.appStatus] || 'critical'}>{json.appStatus}</Badge>);
    } catch (error) {
      // Handle fetch errors here
      console.error('Error fetching metrics:', error);
    }
  };

  useEffect(() => {
    // Initial load
    updateMetrics();
  }, []);

  useInterval(updateMetrics, REFRESH_RATE);

  return (
    <LegacyCard sectioned={true} title="Sprout Dashboard">
      <LegacyStack distribution={"fillEvenly"}>
        <LegacyCard.Section>
          <p>App status</p>
          {appStatus}
        </LegacyCard.Section>

        {/* Section */}
        <LegacyCard.Section>
          <p>Total trees planted</p>
          <br />
          <Text variant="headingMd" as="h2">
            {metrics.totalLifetime}
          </Text>
          {freeAnnotation}
        </LegacyCard.Section>
        {/* Section */}
        <LegacyCard.Section>
          <p>Tree planting limit</p>
          <br />
          <Text variant="headingMd" as="h2">
            {metrics.progressPeriod}%
          </Text>
          <ProgressBar color={metrics.progressPeriod > 85 ? 'critical' : undefined} progress={metrics.progressPeriod} />
          {metrics.TreesRemainingBlob}
          {metrics.totalLifetime < 50 ? <p style={{ fontSize: '8px', color: 'gray' }}>*Free trees do not impact limit</p> : ''}
        </LegacyCard.Section>
        {/* Section */}
        <LegacyCard.Section>
          <p>Limit resets on</p>
          <br />
          <Text variant="headingMd" as="h2">
            {metrics.refreshDate || 'No limit set yet'}
          </Text>
        </LegacyCard.Section>
        
      </LegacyStack>
    </LegacyCard>
  );
}
