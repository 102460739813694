import { Text,Card, InlineError,Banner,Collapsible, Link,TextField, ChoiceList,  Button, Layout, Page, Stack, Toast, Frame, ButtonGroup, Form, FormLayout, TextContainer, ProgressBar, Select, VerticalStack } from "@shopify/polaris"
import React from "react"
import NumberInputSetting from "./numberinputsetting"
import { update } from "@shopify/app-bridge/actions/Button"
import { useAppBridge } from "@shopify/app-bridge-react"
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { isProd } from "../backend"
import { Modal } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { FcHighPriority } from "react-icons/fc";
 

export default function Instructions({buttonLabel, content=[], disabled=false, bann}) {
    const [open, setOpen] = React.useState(false);
  
    const handleToggle = React.useCallback(() => setOpen((open) => !open), []);

    const resp = function() {
     
      let data = []
      for (let i=0; i<content.length; i++){
        data.push(<div><Text variant="headingMd" as="h2">{content[i].heading}</Text><br></br>{content[i].details}</div>)
      }

      
      return (<TextContainer>
        {data}
      </TextContainer>)
    }
    return (
      <div  style={{
        "padding-left":"5%",
        "padding-right":"5%",
        "padding-bottom":"2%",
      }}>
          <VerticalStack>
            <Button
             primary={true}
              onClick={handleToggle}
              ariaExpanded={open}
              ariaControls="basic-collapsible"
              disabled={disabled}
            
              
            >
              {buttonLabel}
            </Button>
            {disabled?<InlineError message="Set Tree Limit" fieldID="tree_limit"/>:<div></div>}

            <Collapsible
              open={open}
              id="basic-collapsible"
              transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
              expandOnPrint
            >
                
             {resp()}
             
            </Collapsible>
            
          </VerticalStack>
      </div>
    );
  }