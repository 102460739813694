export { createMetadata, metadata } from '../src/metadata.mjs';
export { createVar, getCustomPropertyNames, getKeyframeNames, getMediaConditions, getUnit, isKeyOf, rem, removeMetadata, toEm, toPx, toRem, tokensToRems } from '../src/utilities.mjs';
export { breakpointsAliases } from '../src/token-groups/breakpoints.mjs';

var breakpoints = {
  "breakpoints-xs": "0rem",
  "breakpoints-sm": "30.625rem",
  "breakpoints-md": "48rem",
  "breakpoints-lg": "65rem",
  "breakpoints-xl": "90rem"
};
var border = {
  "border-radius-0-experimental": "0rem",
  "border-radius-05": "0.125rem",
  "border-radius-1": "0.25rem",
  "border-radius-2": "0.5rem",
  "border-radius-3": "0.75rem",
  "border-radius-4": "1rem",
  "border-radius-5": "1.25rem",
  "border-radius-6": "1.875rem",
  "border-radius-full": "624.9375rem",
  "border-radius-1_5-experimental": "0.375rem",
  "border-width-1": "0.0625rem",
  "border-width-2": "0.125rem",
  "border-width-3": "0.1875rem",
  "border-width-4": "0.25rem",
  "border-width-5": "0.3125rem",
  "border-width-1-experimental": "0.04125rem",
  "border-width-2-experimental": "0.0625rem"
};
var color = {
  "color-bg-inverse": "rgba(31, 33, 36, 1)",
  "color-bg-inset-strong": "rgba(97, 106, 117, 1)",
  "color-bg-inverse-hover": "rgba(97, 106, 117, 1)",
  "color-bg-inverse-active": "rgba(135, 144, 155, 1)",
  "color-bg-strong-hover": "rgba(202, 206, 211, 1)",
  "color-bg-strong-active": "rgba(202, 206, 211, 1)",
  "color-bg-strong": "rgba(221, 224, 228, 1)",
  "color-bg-subdued-active": "rgba(235, 236, 239, 1)",
  "color-bg-disabled": "rgba(235, 236, 239, 1)",
  "color-bg-interactive-disabled": "rgba(235, 236, 239, 1)",
  "color-bg-app": "rgba(241, 242, 244, 1)",
  "color-bg-app-active": "rgba(221, 224, 228, 1)",
  "color-bg-app-hover": "rgba(235, 236, 239, 1)",
  "color-bg-app-selected": "rgba(235, 236, 239, 1)",
  "color-bg-active": "rgba(235, 236, 239, 1)",
  "color-bg-subdued-hover": "rgba(241, 242, 244, 1)",
  "color-bg-inset": "rgba(241, 242, 244, 1)",
  "color-bg-hover": "rgba(241, 242, 244, 1)",
  "color-bg-subdued": "rgba(249, 250, 251, 1)",
  "color-bg-input": "rgba(255, 255, 255, 1)",
  "color-bg": "rgba(255, 255, 255, 1)",
  "color-bg-primary-active": "rgba(12, 59, 47, 1)",
  "color-bg-primary-hover": "rgba(18, 84, 67, 1)",
  "color-bg-primary": "rgba(0, 122, 92, 1)",
  "color-bg-success-strong": "rgba(22, 166, 121, 1)",
  "color-bg-success": "rgba(161, 237, 208, 1)",
  "color-bg-primary-subdued-active": "rgba(192, 242, 221, 1)",
  "color-bg-success-subdued-active": "rgba(192, 242, 221, 1)",
  "color-bg-success-subdued": "rgba(224, 248, 238, 1)",
  "color-bg-primary-subdued-hover": "rgba(224, 248, 238, 1)",
  "color-bg-success-subdued-hover": "rgba(240, 253, 248, 1)",
  "color-bg-primary-subdued": "rgba(240, 253, 248, 1)",
  "color-bg-primary-subdued-selected": "rgba(240, 253, 248, 1)",
  "color-bg-critical-strong-active": "rgba(115, 24, 7, 1)",
  "color-bg-critical-strong-hover": "rgba(159, 32, 10, 1)",
  "color-bg-critical-strong": "rgba(197, 40, 12, 1)",
  "color-bg-critical-subdued-active": "rgba(251, 197, 188, 1)",
  "color-bg-critical": "rgba(251, 197, 188, 1)",
  "color-bg-critical-subdued": "rgba(253, 226, 221, 1)",
  "color-bg-critical-subdued-hover": "rgba(254, 243, 241, 1)",
  "color-bg-caution-strong": "rgba(216, 155, 13, 1)",
  "color-bg-caution": "rgba(248, 217, 144, 1)",
  "color-bg-caution-subdued-active": "rgba(250, 229, 178, 1)",
  "color-bg-caution-subdued": "rgba(252, 240, 212, 1)",
  "color-bg-caution-subdued-hover": "rgba(254, 248, 236, 1)",
  "color-bg-info-strong": "rgba(42, 172, 187, 1)",
  "color-bg-info-subdued-active": "rgba(184, 233, 239, 1)",
  "color-bg-info": "rgba(184, 233, 239, 1)",
  "color-bg-info-subdued": "rgba(222, 245, 247, 1)",
  "color-bg-info-subdued-hover": "rgba(238, 250, 251, 1)",
  "color-bg-interactive-active": "rgba(14, 53, 108, 1)",
  "color-bg-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-bg-interactive": "rgba(36, 99, 188, 1)",
  "color-bg-interactive-subdued-active": "rgba(187, 212, 247, 1)",
  "color-bg-interactive-subdued-hover": "rgba(232, 240, 253, 1)",
  "color-bg-interactive-subdued": "rgba(240, 245, 253, 1)",
  "color-bg-interactive-selected": "rgba(240, 245, 253, 1)",
  "color-bg-warning": "rgba(250, 201, 168, 1)",
  "color-bg-magic-strong": "rgba(121, 69, 227, 1)",
  "color-bg-magic-hover": "rgba(226, 214, 250, 1)",
  "color-bg-magic-active": "rgba(203, 180, 248, 1)",
  "color-bg-magic": "rgba(236, 227, 253, 1)",
  "color-bg-magic-subdued-hover": "rgba(236, 227, 253, 1)",
  "color-bg-magic-subdued-active": "rgba(226, 214, 250, 1)",
  "color-bg-magic-subdued": "rgba(242, 237, 253, 1)",
  "color-border-input-hover": "rgba(97, 106, 117, 1)",
  "color-border-inverse": "rgba(97, 106, 117, 1)",
  "color-border-strong-hover": "rgba(135, 144, 155, 1)",
  "color-border-input": "rgba(171, 177, 186, 1)",
  "color-border-hover": "rgba(171, 177, 186, 1)",
  "color-border-strong": "rgba(171, 177, 186, 1)",
  "color-border": "rgba(202, 206, 211, 1)",
  "color-border-disabled": "rgba(221, 224, 228, 1)",
  "color-border-subdued": "rgba(221, 224, 228, 1)",
  "color-border-interactive-disabled": "rgba(221, 224, 228, 1)",
  "color-border-primary": "rgba(0, 122, 92, 1)",
  "color-border-success": "rgba(22, 166, 121, 1)",
  "color-border-success-subdued": "rgba(80, 220, 169, 1)",
  "color-border-critical-active": "rgba(67, 14, 4, 1)",
  "color-border-critical-hover": "rgba(115, 24, 7, 1)",
  "color-border-critical": "rgba(197, 40, 12, 1)",
  "color-border-critical-subdued": "rgba(245, 107, 82, 1)",
  "color-border-caution": "rgba(216, 155, 13, 1)",
  "color-border-caution-subdued": "rgba(245, 196, 82, 1)",
  "color-border-info": "rgba(59, 195, 211, 1)",
  "color-border-info-subdued": "rgba(109, 211, 222, 1)",
  "color-border-interactive-active": "rgba(14, 53, 108, 1)",
  "color-border-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-border-interactive": "rgba(62, 125, 213, 1)",
  "color-border-interactive-focus": "rgba(62, 125, 213, 1)",
  "color-border-interactive-subdued": "rgba(187, 212, 247, 1)",
  "color-border-magic-strong": "rgba(121, 69, 227, 1)",
  "color-border-magic": "rgba(173, 139, 241, 1)",
  "color-icon-hover": "rgba(31, 33, 36, 1)",
  "color-icon": "rgba(97, 106, 117, 1)",
  "color-icon-active": "rgba(31, 33, 36, 1)",
  "color-icon-subdued": "rgba(135, 144, 155, 1)",
  "color-icon-disabled": "rgba(171, 177, 186, 1)",
  "color-icon-interactive-disabled": "rgba(171, 177, 186, 1)",
  "color-icon-inverse": "rgba(221, 224, 228, 1)",
  "color-icon-on-color": "rgba(255, 255, 255, 1)",
  "color-icon-primary": "rgba(0, 122, 92, 1)",
  "color-icon-success": "rgba(22, 166, 121, 1)",
  "color-icon-critical": "rgba(197, 40, 12, 1)",
  "color-icon-caution": "rgba(183, 126, 11, 1)",
  "color-icon-info": "rgba(42, 172, 187, 1)",
  "color-icon-warning": "rgba(242, 117, 34, 1)",
  "color-icon-interactive-active": "rgba(14, 53, 108, 1)",
  "color-icon-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-icon-interactive": "rgba(36, 99, 188, 1)",
  "color-icon-interactive-inverse": "rgba(102, 153, 225, 1)",
  "color-icon-magic": "rgba(121, 69, 227, 1)",
  "color-text": "rgba(31, 33, 36, 1)",
  "color-text-subdued": "rgba(97, 106, 117, 1)",
  "color-text-disabled": "rgba(135, 144, 155, 1)",
  "color-text-interactive-disabled": "rgba(135, 144, 155, 1)",
  "color-text-inverse-subdued": "rgba(171, 177, 186, 1)",
  "color-text-inverse": "rgba(241, 242, 244, 1)",
  "color-text-on-color": "rgba(255, 255, 255, 1)",
  "color-text-success-strong": "rgba(12, 59, 47, 1)",
  "color-text-success": "rgba(0, 122, 92, 1)",
  "color-text-primary": "rgba(0, 122, 92, 1)",
  "color-text-primary-hover": "rgba(18, 84, 67, 1)",
  "color-text-critical-strong": "rgba(67, 14, 4, 1)",
  "color-text-critical-active": "rgba(115, 24, 7, 1)",
  "color-text-critical": "rgba(197, 40, 12, 1)",
  "color-text-caution-strong": "rgba(77, 46, 5, 1)",
  "color-text-caution": "rgba(135, 92, 8, 1)",
  "color-text-info-strong": "rgba(16, 65, 71, 1)",
  "color-text-info": "rgba(32, 130, 141, 1)",
  "color-text-warning-strong": "rgba(77, 36, 5, 1)",
  "color-text-interactive-active": "rgba(14, 53, 108, 1)",
  "color-text-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-text-interactive": "rgba(36, 99, 188, 1)",
  "color-text-interactive-inverse": "rgba(102, 153, 225, 1)",
  "color-text-magic-strong": "rgba(49, 13, 120, 1)",
  "color-text-magic": "rgba(90, 36, 205, 1)",
  "color-bg-backdrop-experimental": "rgba(26, 26, 26, 0.75)",
  "color-bg-primary-disabled-experimental": "rgba(212, 212, 212, 1)",
  "color-bg-secondary-experimental": "rgba(243, 243, 243, 1)",
  "color-bg-input-hover-experimental": "rgba(250, 250, 250, 1)",
  "color-border-input-active-experimental": "rgba(26, 26, 26, 1)",
  "color-border-critical-strong-experimental": "rgba(142, 31, 11, 1)",
  "color-bg-input-active-experimental": "rgba(247, 247, 247, 1)",
  "color-bg-transparent-experimental": "rgba(26, 26, 26, 0)",
  "color-bg-transparent-subdued-experimental": "rgba(26, 26, 26, 0.07)",
  "color-bg-transparent-hover-experimental": "rgba(26, 26, 26, 0.05)",
  "color-bg-transparent-active-experimental": "rgba(26, 26, 26, 0.07)",
  "color-bg-transparent-disabled-experimental": "rgba(26, 26, 26, 0.04)",
  "color-bg-transparent-secondary-disabled-experimental": "rgba(26, 26, 26, 0.08)",
  "color-bg-transparent-primary-disabled-experimental": "rgba(26, 26, 26, 0.18)",
  "color-bg-transparent-primary-experimental": "rgba(26, 26, 26, 0.60)",
  "color-bg-success-strong-hover-experimental": "rgba(19, 111, 69, 1)",
  "color-bg-success-strong-active-experimental": "rgba(12, 81, 50, 1)",
  "color-bg-warning-subdued-experimental": "rgba(255, 241, 227, 1)",
  "color-bg-warning-strong-experimental": "rgba(255, 184, 0, 1)",
  "color-text-warning-experimental": "rgba(65, 45, 0, 1)",
  "color-text-critical-hover-experimental": "rgba(95, 21, 7, 1)",
  "color-icon-info-strong-experimental": "rgba(0, 82, 124, 1)",
  "color-icon-warning-strong-experimental": "rgba(124, 88, 0, 1)",
  "color-icon-success-strong-experimental": "rgba(12, 81, 50, 1)",
  "color-icon-critical-strong-experimental": "rgba(142, 31, 11, 1)",
  "color-icon-critical-strong-hover-experimental": "rgba(95, 21, 7, 1)",
  "color-icon-critical-strong-active-experimental": "rgba(47, 10, 4, 1)",
  "color-avatar-background-experimental": "rgba(181, 181, 181, 1)",
  "color-avatar-color-experimental": "rgba(255, 255, 255, 1)",
  "color-avatar-style-one-background-experimental": "rgba(251, 207, 251, 1)",
  "color-avatar-style-one-color-experimental": "rgba(121, 26, 121, 1)",
  "color-avatar-style-two-background-experimental": "rgba(56, 250, 163, 1)",
  "color-avatar-style-two-color-experimental": "rgba(12, 81, 50, 1)",
  "color-avatar-style-three-background-experimental": "rgba(146, 237, 222, 1)",
  "color-avatar-style-three-color-experimental": "rgba(9, 83, 70, 1)",
  "color-avatar-style-four-background-experimental": "rgba(192, 225, 255, 1)",
  "color-avatar-style-four-color-experimental": "rgba(0, 82, 124, 1)",
  "color-avatar-style-five-background-experimental": "rgba(254, 209, 221, 1)",
  "color-avatar-style-five-color-experimental": "rgba(141, 4, 72, 1)"
};
var font = {
  "font-family-sans": "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
  "font-family-mono": "ui-monospace, SFMono-Regular, 'SF Mono', Consolas, 'Liberation Mono', Menlo, monospace",
  "font-size-70-experimental": "0.6875rem",
  "font-size-75": "0.75rem",
  "font-size-80-experimental": "0.8125rem",
  "font-size-100": "0.875rem",
  "font-size-200": "1rem",
  "font-size-300": "1.25rem",
  "font-size-400": "1.5rem",
  "font-size-500": "1.75rem",
  "font-size-600": "2rem",
  "font-size-700": "2.5rem",
  "font-weight-regular": "400",
  "font-weight-medium": "500",
  "font-weight-semibold": "600",
  "font-weight-bold": "700",
  "font-line-height-075-experimental": "0.75rem",
  "font-line-height-1": "1rem",
  "font-line-height-2": "1.25rem",
  "font-line-height-3": "1.5rem",
  "font-line-height-4": "1.75rem",
  "font-line-height-5": "2rem",
  "font-line-height-6": "2.5rem",
  "font-line-height-7": "3rem"
};
var motion = {
  "motion-duration-0": "0ms",
  "motion-duration-50": "50ms",
  "motion-duration-100": "100ms",
  "motion-duration-150": "150ms",
  "motion-duration-200": "200ms",
  "motion-duration-250": "250ms",
  "motion-duration-300": "300ms",
  "motion-duration-350": "350ms",
  "motion-duration-400": "400ms",
  "motion-duration-450": "450ms",
  "motion-duration-500": "500ms",
  "motion-duration-5000": "5000ms",
  "motion-ease": "cubic-bezier(0.25, 0.1, 0.25, 1)",
  "motion-ease-in": "cubic-bezier(0.42, 0, 1, 1)",
  "motion-ease-out": "cubic-bezier(0, 0, 0.58, 1)",
  "motion-ease-in-out": "cubic-bezier(0.42, 0, 0.58, 1)",
  "motion-linear": "cubic-bezier(0, 0, 1, 1)",
  "motion-keyframes-bounce": "{ from, 65%, 85% { transform: scale(1) } 75% { transform: scale(0.85) } 82.5% { transform: scale(1.05) } }",
  "motion-keyframes-fade-in": "{ to { opacity: 1 } }",
  "motion-keyframes-pulse": "{ from, 75% { transform: scale(0.85); opacity: 1; } to { transform: scale(2.5); opacity: 0; } }",
  "motion-keyframes-spin": "{ to { transform: rotate(1turn) } }",
  "motion-keyframes-appear-above": "{ from { transform: translateY(var(--p-space-1)); opacity: 0; } to { transform: none; opacity: 1; } }",
  "motion-keyframes-appear-below": "{ from { transform: translateY(calc(var(--p-space-1) * -1)); opacity: 0; } to { transform: none; opacity: 1; } }"
};
var shadow = {
  "shadow-inset-lg": "inset 0rem 0rem 0.4375rem 0.125rem rgba(31, 33, 36, 0.18)",
  "shadow-inset-md": "inset 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.32)",
  "shadow-inset-sm": "inset 0rem 0rem 0.1875rem rgba(31, 33, 36, 0.56)",
  "shadow-none": "none",
  "shadow-xs": "0rem 0rem 0.125rem rgba(31, 33, 36, 0.24)",
  "shadow-sm": "0rem 0.0625rem 0.0625rem rgba(31, 33, 36, 0.1)",
  "shadow-md": "0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1), 0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05)",
  "shadow-lg": "0rem 0.25rem 0.75rem rgba(31, 33, 36, 0.2), 0rem 0.125rem 0.375rem rgba(31, 33, 36, 0.05)",
  "shadow-xl": "0rem 0.25rem 1.125rem -0.125rem rgba(31, 33, 36, 0.08), 0rem 0.75rem 1.125rem -0.125rem rgba(31, 33, 36, 0.15)",
  "shadow-2xl": "0rem 2rem 2rem rgba(31, 33, 36, 0.15), 0rem 2rem 3.5rem -0.125rem rgba(31, 33, 36, 0.16)",
  "shadow-bevel-experimental": "0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset, -0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset, 0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.17) inset, 0rem 0.0625rem 0rem 0rem rgba(204, 204, 204, 0.5) inset",
  "shadow-card-sm-experimental": "0rem 0.125rem 0rem rgba(0, 0, 0, 0.07), 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.07), 0.0625rem 0rem 0rem #E3E3E3, -0.0625rem 0rem 0rem #E3E3E3, 0rem -0.0625rem 0rem #E3E3E3",
  "shadow-card-md-experimental": "0rem 0.1875rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.07),  0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.16),  0.0625rem 0rem 0rem 0rem rgba(227, 227, 227, 1),  -0.0625rem 0rem 0rem 0rem rgba(227, 227, 227, 1),  0rem 0.0625rem 0rem 0rem rgba(227, 227, 227, 1)",
  "shadow-card-lg-experimental": "0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.2), inset 0rem -0.0625rem 0rem #D4D4D4, inset -0.0625rem 0rem 0rem #E3E3E3, inset 0.0625rem 0rem 0rem #E3E3E3, inset 0rem 0.0625rem 0rem #E3E3E3",
  "shadow-button-experimental": "inset 0rem -0.0625rem 0rem #B5B5B5, inset -0.0625rem 0rem 0rem #E3E3E3, inset 0.0625rem 0rem 0rem #E3E3E3, inset 0rem 0.0625rem 0rem #E3E3E3",
  "shadow-button-hover-experimental": "inset 0rem -0.0625rem 0rem #cccccc, inset 0.0625rem 0rem 0rem #ebebeb, inset -0.0625rem 0rem 0rem #ebebeb, inset 0rem 0.0625rem 0rem #ebebeb",
  "shadow-button-disabled-experimental": "inset 0rem 0rem 0rem 0.0625rem rgba(227, 227, 227, 1)",
  "shadow-button-primary-strong-inset-experimental": "0rem 0.1875rem 0rem 0rem #000 inset",
  "shadow-button-primary-strong-hover-experimental": "0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.24) inset, 0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.16) inset, -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.16) inset, 0rem -0.09375rem 0rem 0rem rgba(255, 255, 255, 0.07) inset, 0rem 0rem 0rem 0.03125rem #1A1A1A",
  "shadow-button-primary-strong-experimental": "0rem 0.125rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, -0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0rem -0.0625rem 0rem 0.0625rem #000 inset, 0rem 0.0625rem 0rem 0rem #000 inset",
  "shadow-button-primary-experimental": "0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.4) inset, 0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0rem -0.09375rem 0rem 0rem rgba(0, 0, 0, 0.25) inset",
  "shadow-button-primary-hover-experimental": "-0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.20) inset, 0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.20) inset, 0rem 0.03125rem 0rem 0rem rgba(0, 0, 0, 0.25), 0rem -0.09375rem 0rem 0rem rgba(255, 255, 255, 0.07) inset, 0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.24) inset",
  "shadow-button-inset-experimental": "0rem 0.125rem 0rem 0rem rgba(0, 0, 0, 0.60) inset, 0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.20) inset, -0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.20) inset",
  "shadow-border-inset-experimental": "0rem 0rem 0rem 0.0625rem rgba(0, 0, 0, 0.08) inset"
};
var space = {
  "space-0": "0rem",
  "space-025": "0.0625rem",
  "space-05": "0.125rem",
  "space-1": "0.25rem",
  "space-1_5-experimental": "0.375rem",
  "space-2": "0.5rem",
  "space-3": "0.75rem",
  "space-4": "1rem",
  "space-5": "1.25rem",
  "space-6": "1.5rem",
  "space-8": "2rem",
  "space-10": "2.5rem",
  "space-12": "3rem",
  "space-16": "4rem",
  "space-20": "5rem",
  "space-24": "6rem",
  "space-28": "7rem",
  "space-32": "8rem"
};
var zIndex = {
  "z-index-1": "100",
  "z-index-2": "400",
  "z-index-3": "510",
  "z-index-4": "512",
  "z-index-5": "513",
  "z-index-6": "514",
  "z-index-7": "515",
  "z-index-8": "516",
  "z-index-9": "517",
  "z-index-10": "518",
  "z-index-11": "519",
  "z-index-12": "520"
};
var tokens = {
  "breakpoints": {
    "breakpoints-xs": "0rem",
    "breakpoints-sm": "30.625rem",
    "breakpoints-md": "48rem",
    "breakpoints-lg": "65rem",
    "breakpoints-xl": "90rem"
  },
  "border": {
    "border-radius-0-experimental": "0rem",
    "border-radius-05": "0.125rem",
    "border-radius-1": "0.25rem",
    "border-radius-2": "0.5rem",
    "border-radius-3": "0.75rem",
    "border-radius-4": "1rem",
    "border-radius-5": "1.25rem",
    "border-radius-6": "1.875rem",
    "border-radius-full": "624.9375rem",
    "border-radius-1_5-experimental": "0.375rem",
    "border-width-1": "0.0625rem",
    "border-width-2": "0.125rem",
    "border-width-3": "0.1875rem",
    "border-width-4": "0.25rem",
    "border-width-5": "0.3125rem",
    "border-width-1-experimental": "0.04125rem",
    "border-width-2-experimental": "0.0625rem"
  },
  "color": {
    "color-bg-inverse": "rgba(31, 33, 36, 1)",
    "color-bg-inset-strong": "rgba(97, 106, 117, 1)",
    "color-bg-inverse-hover": "rgba(97, 106, 117, 1)",
    "color-bg-inverse-active": "rgba(135, 144, 155, 1)",
    "color-bg-strong-hover": "rgba(202, 206, 211, 1)",
    "color-bg-strong-active": "rgba(202, 206, 211, 1)",
    "color-bg-strong": "rgba(221, 224, 228, 1)",
    "color-bg-subdued-active": "rgba(235, 236, 239, 1)",
    "color-bg-disabled": "rgba(235, 236, 239, 1)",
    "color-bg-interactive-disabled": "rgba(235, 236, 239, 1)",
    "color-bg-app": "rgba(241, 242, 244, 1)",
    "color-bg-app-active": "rgba(221, 224, 228, 1)",
    "color-bg-app-hover": "rgba(235, 236, 239, 1)",
    "color-bg-app-selected": "rgba(235, 236, 239, 1)",
    "color-bg-active": "rgba(235, 236, 239, 1)",
    "color-bg-subdued-hover": "rgba(241, 242, 244, 1)",
    "color-bg-inset": "rgba(241, 242, 244, 1)",
    "color-bg-hover": "rgba(241, 242, 244, 1)",
    "color-bg-subdued": "rgba(249, 250, 251, 1)",
    "color-bg-input": "rgba(255, 255, 255, 1)",
    "color-bg": "rgba(255, 255, 255, 1)",
    "color-bg-primary-active": "rgba(12, 59, 47, 1)",
    "color-bg-primary-hover": "rgba(18, 84, 67, 1)",
    "color-bg-primary": "rgba(0, 122, 92, 1)",
    "color-bg-success-strong": "rgba(22, 166, 121, 1)",
    "color-bg-success": "rgba(161, 237, 208, 1)",
    "color-bg-primary-subdued-active": "rgba(192, 242, 221, 1)",
    "color-bg-success-subdued-active": "rgba(192, 242, 221, 1)",
    "color-bg-success-subdued": "rgba(224, 248, 238, 1)",
    "color-bg-primary-subdued-hover": "rgba(224, 248, 238, 1)",
    "color-bg-success-subdued-hover": "rgba(240, 253, 248, 1)",
    "color-bg-primary-subdued": "rgba(240, 253, 248, 1)",
    "color-bg-primary-subdued-selected": "rgba(240, 253, 248, 1)",
    "color-bg-critical-strong-active": "rgba(115, 24, 7, 1)",
    "color-bg-critical-strong-hover": "rgba(159, 32, 10, 1)",
    "color-bg-critical-strong": "rgba(197, 40, 12, 1)",
    "color-bg-critical-subdued-active": "rgba(251, 197, 188, 1)",
    "color-bg-critical": "rgba(251, 197, 188, 1)",
    "color-bg-critical-subdued": "rgba(253, 226, 221, 1)",
    "color-bg-critical-subdued-hover": "rgba(254, 243, 241, 1)",
    "color-bg-caution-strong": "rgba(216, 155, 13, 1)",
    "color-bg-caution": "rgba(248, 217, 144, 1)",
    "color-bg-caution-subdued-active": "rgba(250, 229, 178, 1)",
    "color-bg-caution-subdued": "rgba(252, 240, 212, 1)",
    "color-bg-caution-subdued-hover": "rgba(254, 248, 236, 1)",
    "color-bg-info-strong": "rgba(42, 172, 187, 1)",
    "color-bg-info-subdued-active": "rgba(184, 233, 239, 1)",
    "color-bg-info": "rgba(184, 233, 239, 1)",
    "color-bg-info-subdued": "rgba(222, 245, 247, 1)",
    "color-bg-info-subdued-hover": "rgba(238, 250, 251, 1)",
    "color-bg-interactive-active": "rgba(14, 53, 108, 1)",
    "color-bg-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-bg-interactive": "rgba(36, 99, 188, 1)",
    "color-bg-interactive-subdued-active": "rgba(187, 212, 247, 1)",
    "color-bg-interactive-subdued-hover": "rgba(232, 240, 253, 1)",
    "color-bg-interactive-subdued": "rgba(240, 245, 253, 1)",
    "color-bg-interactive-selected": "rgba(240, 245, 253, 1)",
    "color-bg-warning": "rgba(250, 201, 168, 1)",
    "color-bg-magic-strong": "rgba(121, 69, 227, 1)",
    "color-bg-magic-hover": "rgba(226, 214, 250, 1)",
    "color-bg-magic-active": "rgba(203, 180, 248, 1)",
    "color-bg-magic": "rgba(236, 227, 253, 1)",
    "color-bg-magic-subdued-hover": "rgba(236, 227, 253, 1)",
    "color-bg-magic-subdued-active": "rgba(226, 214, 250, 1)",
    "color-bg-magic-subdued": "rgba(242, 237, 253, 1)",
    "color-border-input-hover": "rgba(97, 106, 117, 1)",
    "color-border-inverse": "rgba(97, 106, 117, 1)",
    "color-border-strong-hover": "rgba(135, 144, 155, 1)",
    "color-border-input": "rgba(171, 177, 186, 1)",
    "color-border-hover": "rgba(171, 177, 186, 1)",
    "color-border-strong": "rgba(171, 177, 186, 1)",
    "color-border": "rgba(202, 206, 211, 1)",
    "color-border-disabled": "rgba(221, 224, 228, 1)",
    "color-border-subdued": "rgba(221, 224, 228, 1)",
    "color-border-interactive-disabled": "rgba(221, 224, 228, 1)",
    "color-border-primary": "rgba(0, 122, 92, 1)",
    "color-border-success": "rgba(22, 166, 121, 1)",
    "color-border-success-subdued": "rgba(80, 220, 169, 1)",
    "color-border-critical-active": "rgba(67, 14, 4, 1)",
    "color-border-critical-hover": "rgba(115, 24, 7, 1)",
    "color-border-critical": "rgba(197, 40, 12, 1)",
    "color-border-critical-subdued": "rgba(245, 107, 82, 1)",
    "color-border-caution": "rgba(216, 155, 13, 1)",
    "color-border-caution-subdued": "rgba(245, 196, 82, 1)",
    "color-border-info": "rgba(59, 195, 211, 1)",
    "color-border-info-subdued": "rgba(109, 211, 222, 1)",
    "color-border-interactive-active": "rgba(14, 53, 108, 1)",
    "color-border-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-border-interactive": "rgba(62, 125, 213, 1)",
    "color-border-interactive-focus": "rgba(62, 125, 213, 1)",
    "color-border-interactive-subdued": "rgba(187, 212, 247, 1)",
    "color-border-magic-strong": "rgba(121, 69, 227, 1)",
    "color-border-magic": "rgba(173, 139, 241, 1)",
    "color-icon-hover": "rgba(31, 33, 36, 1)",
    "color-icon": "rgba(97, 106, 117, 1)",
    "color-icon-active": "rgba(31, 33, 36, 1)",
    "color-icon-subdued": "rgba(135, 144, 155, 1)",
    "color-icon-disabled": "rgba(171, 177, 186, 1)",
    "color-icon-interactive-disabled": "rgba(171, 177, 186, 1)",
    "color-icon-inverse": "rgba(221, 224, 228, 1)",
    "color-icon-on-color": "rgba(255, 255, 255, 1)",
    "color-icon-primary": "rgba(0, 122, 92, 1)",
    "color-icon-success": "rgba(22, 166, 121, 1)",
    "color-icon-critical": "rgba(197, 40, 12, 1)",
    "color-icon-caution": "rgba(183, 126, 11, 1)",
    "color-icon-info": "rgba(42, 172, 187, 1)",
    "color-icon-warning": "rgba(242, 117, 34, 1)",
    "color-icon-interactive-active": "rgba(14, 53, 108, 1)",
    "color-icon-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-icon-interactive": "rgba(36, 99, 188, 1)",
    "color-icon-interactive-inverse": "rgba(102, 153, 225, 1)",
    "color-icon-magic": "rgba(121, 69, 227, 1)",
    "color-text": "rgba(31, 33, 36, 1)",
    "color-text-subdued": "rgba(97, 106, 117, 1)",
    "color-text-disabled": "rgba(135, 144, 155, 1)",
    "color-text-interactive-disabled": "rgba(135, 144, 155, 1)",
    "color-text-inverse-subdued": "rgba(171, 177, 186, 1)",
    "color-text-inverse": "rgba(241, 242, 244, 1)",
    "color-text-on-color": "rgba(255, 255, 255, 1)",
    "color-text-success-strong": "rgba(12, 59, 47, 1)",
    "color-text-success": "rgba(0, 122, 92, 1)",
    "color-text-primary": "rgba(0, 122, 92, 1)",
    "color-text-primary-hover": "rgba(18, 84, 67, 1)",
    "color-text-critical-strong": "rgba(67, 14, 4, 1)",
    "color-text-critical-active": "rgba(115, 24, 7, 1)",
    "color-text-critical": "rgba(197, 40, 12, 1)",
    "color-text-caution-strong": "rgba(77, 46, 5, 1)",
    "color-text-caution": "rgba(135, 92, 8, 1)",
    "color-text-info-strong": "rgba(16, 65, 71, 1)",
    "color-text-info": "rgba(32, 130, 141, 1)",
    "color-text-warning-strong": "rgba(77, 36, 5, 1)",
    "color-text-interactive-active": "rgba(14, 53, 108, 1)",
    "color-text-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-text-interactive": "rgba(36, 99, 188, 1)",
    "color-text-interactive-inverse": "rgba(102, 153, 225, 1)",
    "color-text-magic-strong": "rgba(49, 13, 120, 1)",
    "color-text-magic": "rgba(90, 36, 205, 1)",
    "color-bg-backdrop-experimental": "rgba(26, 26, 26, 0.75)",
    "color-bg-primary-disabled-experimental": "rgba(212, 212, 212, 1)",
    "color-bg-secondary-experimental": "rgba(243, 243, 243, 1)",
    "color-bg-input-hover-experimental": "rgba(250, 250, 250, 1)",
    "color-border-input-active-experimental": "rgba(26, 26, 26, 1)",
    "color-border-critical-strong-experimental": "rgba(142, 31, 11, 1)",
    "color-bg-input-active-experimental": "rgba(247, 247, 247, 1)",
    "color-bg-transparent-experimental": "rgba(26, 26, 26, 0)",
    "color-bg-transparent-subdued-experimental": "rgba(26, 26, 26, 0.07)",
    "color-bg-transparent-hover-experimental": "rgba(26, 26, 26, 0.05)",
    "color-bg-transparent-active-experimental": "rgba(26, 26, 26, 0.07)",
    "color-bg-transparent-disabled-experimental": "rgba(26, 26, 26, 0.04)",
    "color-bg-transparent-secondary-disabled-experimental": "rgba(26, 26, 26, 0.08)",
    "color-bg-transparent-primary-disabled-experimental": "rgba(26, 26, 26, 0.18)",
    "color-bg-transparent-primary-experimental": "rgba(26, 26, 26, 0.60)",
    "color-bg-success-strong-hover-experimental": "rgba(19, 111, 69, 1)",
    "color-bg-success-strong-active-experimental": "rgba(12, 81, 50, 1)",
    "color-bg-warning-subdued-experimental": "rgba(255, 241, 227, 1)",
    "color-bg-warning-strong-experimental": "rgba(255, 184, 0, 1)",
    "color-text-warning-experimental": "rgba(65, 45, 0, 1)",
    "color-text-critical-hover-experimental": "rgba(95, 21, 7, 1)",
    "color-icon-info-strong-experimental": "rgba(0, 82, 124, 1)",
    "color-icon-warning-strong-experimental": "rgba(124, 88, 0, 1)",
    "color-icon-success-strong-experimental": "rgba(12, 81, 50, 1)",
    "color-icon-critical-strong-experimental": "rgba(142, 31, 11, 1)",
    "color-icon-critical-strong-hover-experimental": "rgba(95, 21, 7, 1)",
    "color-icon-critical-strong-active-experimental": "rgba(47, 10, 4, 1)",
    "color-avatar-background-experimental": "rgba(181, 181, 181, 1)",
    "color-avatar-color-experimental": "rgba(255, 255, 255, 1)",
    "color-avatar-style-one-background-experimental": "rgba(251, 207, 251, 1)",
    "color-avatar-style-one-color-experimental": "rgba(121, 26, 121, 1)",
    "color-avatar-style-two-background-experimental": "rgba(56, 250, 163, 1)",
    "color-avatar-style-two-color-experimental": "rgba(12, 81, 50, 1)",
    "color-avatar-style-three-background-experimental": "rgba(146, 237, 222, 1)",
    "color-avatar-style-three-color-experimental": "rgba(9, 83, 70, 1)",
    "color-avatar-style-four-background-experimental": "rgba(192, 225, 255, 1)",
    "color-avatar-style-four-color-experimental": "rgba(0, 82, 124, 1)",
    "color-avatar-style-five-background-experimental": "rgba(254, 209, 221, 1)",
    "color-avatar-style-five-color-experimental": "rgba(141, 4, 72, 1)"
  },
  "font": {
    "font-family-sans": "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
    "font-family-mono": "ui-monospace, SFMono-Regular, 'SF Mono', Consolas, 'Liberation Mono', Menlo, monospace",
    "font-size-70-experimental": "0.6875rem",
    "font-size-75": "0.75rem",
    "font-size-80-experimental": "0.8125rem",
    "font-size-100": "0.875rem",
    "font-size-200": "1rem",
    "font-size-300": "1.25rem",
    "font-size-400": "1.5rem",
    "font-size-500": "1.75rem",
    "font-size-600": "2rem",
    "font-size-700": "2.5rem",
    "font-weight-regular": "400",
    "font-weight-medium": "500",
    "font-weight-semibold": "600",
    "font-weight-bold": "700",
    "font-line-height-075-experimental": "0.75rem",
    "font-line-height-1": "1rem",
    "font-line-height-2": "1.25rem",
    "font-line-height-3": "1.5rem",
    "font-line-height-4": "1.75rem",
    "font-line-height-5": "2rem",
    "font-line-height-6": "2.5rem",
    "font-line-height-7": "3rem"
  },
  "motion": {
    "motion-duration-0": "0ms",
    "motion-duration-50": "50ms",
    "motion-duration-100": "100ms",
    "motion-duration-150": "150ms",
    "motion-duration-200": "200ms",
    "motion-duration-250": "250ms",
    "motion-duration-300": "300ms",
    "motion-duration-350": "350ms",
    "motion-duration-400": "400ms",
    "motion-duration-450": "450ms",
    "motion-duration-500": "500ms",
    "motion-duration-5000": "5000ms",
    "motion-ease": "cubic-bezier(0.25, 0.1, 0.25, 1)",
    "motion-ease-in": "cubic-bezier(0.42, 0, 1, 1)",
    "motion-ease-out": "cubic-bezier(0, 0, 0.58, 1)",
    "motion-ease-in-out": "cubic-bezier(0.42, 0, 0.58, 1)",
    "motion-linear": "cubic-bezier(0, 0, 1, 1)",
    "motion-keyframes-bounce": "{ from, 65%, 85% { transform: scale(1) } 75% { transform: scale(0.85) } 82.5% { transform: scale(1.05) } }",
    "motion-keyframes-fade-in": "{ to { opacity: 1 } }",
    "motion-keyframes-pulse": "{ from, 75% { transform: scale(0.85); opacity: 1; } to { transform: scale(2.5); opacity: 0; } }",
    "motion-keyframes-spin": "{ to { transform: rotate(1turn) } }",
    "motion-keyframes-appear-above": "{ from { transform: translateY(var(--p-space-1)); opacity: 0; } to { transform: none; opacity: 1; } }",
    "motion-keyframes-appear-below": "{ from { transform: translateY(calc(var(--p-space-1) * -1)); opacity: 0; } to { transform: none; opacity: 1; } }"
  },
  "shadow": {
    "shadow-inset-lg": "inset 0rem 0rem 0.4375rem 0.125rem rgba(31, 33, 36, 0.18)",
    "shadow-inset-md": "inset 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.32)",
    "shadow-inset-sm": "inset 0rem 0rem 0.1875rem rgba(31, 33, 36, 0.56)",
    "shadow-none": "none",
    "shadow-xs": "0rem 0rem 0.125rem rgba(31, 33, 36, 0.24)",
    "shadow-sm": "0rem 0.0625rem 0.0625rem rgba(31, 33, 36, 0.1)",
    "shadow-md": "0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1), 0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05)",
    "shadow-lg": "0rem 0.25rem 0.75rem rgba(31, 33, 36, 0.2), 0rem 0.125rem 0.375rem rgba(31, 33, 36, 0.05)",
    "shadow-xl": "0rem 0.25rem 1.125rem -0.125rem rgba(31, 33, 36, 0.08), 0rem 0.75rem 1.125rem -0.125rem rgba(31, 33, 36, 0.15)",
    "shadow-2xl": "0rem 2rem 2rem rgba(31, 33, 36, 0.15), 0rem 2rem 3.5rem -0.125rem rgba(31, 33, 36, 0.16)",
    "shadow-bevel-experimental": "0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset, -0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset, 0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.17) inset, 0rem 0.0625rem 0rem 0rem rgba(204, 204, 204, 0.5) inset",
    "shadow-card-sm-experimental": "0rem 0.125rem 0rem rgba(0, 0, 0, 0.07), 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.07), 0.0625rem 0rem 0rem #E3E3E3, -0.0625rem 0rem 0rem #E3E3E3, 0rem -0.0625rem 0rem #E3E3E3",
    "shadow-card-md-experimental": "0rem 0.1875rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.07),  0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.16),  0.0625rem 0rem 0rem 0rem rgba(227, 227, 227, 1),  -0.0625rem 0rem 0rem 0rem rgba(227, 227, 227, 1),  0rem 0.0625rem 0rem 0rem rgba(227, 227, 227, 1)",
    "shadow-card-lg-experimental": "0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.2), inset 0rem -0.0625rem 0rem #D4D4D4, inset -0.0625rem 0rem 0rem #E3E3E3, inset 0.0625rem 0rem 0rem #E3E3E3, inset 0rem 0.0625rem 0rem #E3E3E3",
    "shadow-button-experimental": "inset 0rem -0.0625rem 0rem #B5B5B5, inset -0.0625rem 0rem 0rem #E3E3E3, inset 0.0625rem 0rem 0rem #E3E3E3, inset 0rem 0.0625rem 0rem #E3E3E3",
    "shadow-button-hover-experimental": "inset 0rem -0.0625rem 0rem #cccccc, inset 0.0625rem 0rem 0rem #ebebeb, inset -0.0625rem 0rem 0rem #ebebeb, inset 0rem 0.0625rem 0rem #ebebeb",
    "shadow-button-disabled-experimental": "inset 0rem 0rem 0rem 0.0625rem rgba(227, 227, 227, 1)",
    "shadow-button-primary-strong-inset-experimental": "0rem 0.1875rem 0rem 0rem #000 inset",
    "shadow-button-primary-strong-hover-experimental": "0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.24) inset, 0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.16) inset, -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.16) inset, 0rem -0.09375rem 0rem 0rem rgba(255, 255, 255, 0.07) inset, 0rem 0rem 0rem 0.03125rem #1A1A1A",
    "shadow-button-primary-strong-experimental": "0rem 0.125rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, -0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0rem -0.0625rem 0rem 0.0625rem #000 inset, 0rem 0.0625rem 0rem 0rem #000 inset",
    "shadow-button-primary-experimental": "0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.4) inset, 0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0rem -0.09375rem 0rem 0rem rgba(0, 0, 0, 0.25) inset",
    "shadow-button-primary-hover-experimental": "-0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.20) inset, 0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.20) inset, 0rem 0.03125rem 0rem 0rem rgba(0, 0, 0, 0.25), 0rem -0.09375rem 0rem 0rem rgba(255, 255, 255, 0.07) inset, 0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.24) inset",
    "shadow-button-inset-experimental": "0rem 0.125rem 0rem 0rem rgba(0, 0, 0, 0.60) inset, 0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.20) inset, -0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.20) inset",
    "shadow-border-inset-experimental": "0rem 0rem 0rem 0.0625rem rgba(0, 0, 0, 0.08) inset"
  },
  "space": {
    "space-0": "0rem",
    "space-025": "0.0625rem",
    "space-05": "0.125rem",
    "space-1": "0.25rem",
    "space-1_5-experimental": "0.375rem",
    "space-2": "0.5rem",
    "space-3": "0.75rem",
    "space-4": "1rem",
    "space-5": "1.25rem",
    "space-6": "1.5rem",
    "space-8": "2rem",
    "space-10": "2.5rem",
    "space-12": "3rem",
    "space-16": "4rem",
    "space-20": "5rem",
    "space-24": "6rem",
    "space-28": "7rem",
    "space-32": "8rem"
  },
  "zIndex": {
    "z-index-1": "100",
    "z-index-2": "400",
    "z-index-3": "510",
    "z-index-4": "512",
    "z-index-5": "513",
    "z-index-6": "514",
    "z-index-7": "515",
    "z-index-8": "516",
    "z-index-9": "517",
    "z-index-10": "518",
    "z-index-11": "519",
    "z-index-12": "520"
  }
};

export { border, breakpoints, color, font, motion, shadow, space, tokens, zIndex };
