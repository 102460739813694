import React, { useState, useEffect } from 'react';
import {
  Card,
  Box,
  Text,
  Layout,
  TextContainer,
  Button,
  HorizontalStack,
  LegacyCard
} from '@shopify/polaris';
import { useNavigate } from '@shopify/app-bridge-react';
import Crisp from './Crisp';
import Settings from './Settings';
import { useAppBridge } from "@shopify/app-bridge-react";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import { TrackPage, GetShopSettings, GetShopName, GetUpdateURLIfAvailable } from "../backend";


export default function Onboarding({ requestedTab = "settings" }) {
  console.log("Onboarding starting....")
  const [loadState, setLoadState] = useState(false);
  const [loadSettings, setLoadSettings] = useState(false);
  const [updateUrl, setUpdateUrl] = useState("");
  const nav = useNavigate();
  const shopify = useAppBridge();
  const httpFetch = authenticatedFetch(shopify);
  const [currentSavedSettings, setCurrentSavedSettings] = useState({ shopName: "" });

  async function loadSettingsFunc() {
    async function n() {
      const json = await GetShopSettings(httpFetch)
      setCurrentSavedSettings({
        maxTrees: json.maxTrees.toString(),
        numTrees: json.treesPerProduct.toString(),
        threshold: json.valueThreshold.toString(),
        state: json.state,
        themeEditorUrl: json.themeEditorUrl,
        themeCodeEditorUrl: json.themeCodeEditorUrl,
        seenOnboarding: json.seenOnboarding,
        subActive: json.subActive,
        shopName: json.shopName,
        defaultCurrencyCode: json.defaultCurrencyCode,
        defaultCurrencySymbol: json.defaultCurrencySymbol,
        refreshBudgetDate: json.refreshBudgetDate,
        costPerTree: json.costPerTree,
        appStatus: json.appStatus,
        updateUrl: json.updateUrl,
        customerPlantingEnabled: json.customerPlantingEnabled,
        productBlock: json.productBlock,
        cartBlock: json.cartBlock,
        counterBlock: json.counterBlock,
        showTopupFeature: json.showTopupFeature,
        isBlocked: json.isBlocked,
      });
    }
    await n()
  }

  function updateApp() {
    if (updateUrl !== "" && updateUrl !== null) {
      window.top.location.href = updateUrl;
    }
  }

  return (
    <>
    {loadSettings ? (
      <Settings currentSavedSettings={currentSavedSettings} />
      
    ):(
    <Box as="div" style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
      backgroundColor: "#f4f6f8"

    }}>
      <Card>
      <Text as="h1" variant="heading2xl">
         Making a positive impact 🌳
      </Text> <br/><br/>
      <Text as="p" variant="bodyLarge">
      Plant a tree on behalf of your customers for every purchase made on your Shopify store. <br/> It only takes a minute to set up Sprout and to start making a positive impact.
      </Text>
      <br/>
      <br/>
      <HorizontalStack align="end">
                            <Button
                              primary={true}
                              loading={loadState}
                              onClick={async () => {
                                setLoadState(true);
                                if (updateUrl) {
                                  updateApp();
                                } else {
                                  console.log("loading settings")
                                  await loadSettingsFunc();
                                  console.log("loaded settings ended")
                                  setLoadSettings(true);
                                  try {
                                    TrackPage(httpFetch, "clicked onboarding button")
                                  } catch (e) {
                                    console.log("failed to track page")
                                  }
                                }
                              }}
                            >
                              {updateUrl ? "Update App" : "Open App"}
                            </Button>
                          </HorizontalStack>


      </Card>
    </Box>
    )}
    </>
  )

}
