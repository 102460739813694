import { Layout, Button, LegacyCard,Banner,  } from "@shopify/polaris"

import NumberInputSetting from "./numberinputsetting"
import { FcHighPriority } from "react-icons/fc";
import {  AiFillInfoCircle} from "react-icons/ai";
import React from "react";
import { show } from "@shopify/app-bridge/actions/Toast";

export default function BudgetSettings({maxTrees, setMaxTrees, budgetLoading, updateBilling, buttonCopy,  disabled=false, showPriorityIcon=false,refreshBudgetDate, costPerTree=.15, bannerToShow=""}) {
  const hintText = `Maximum spend per month: ${(maxTrees*costPerTree).toFixed(2)} USD`
  const desc = (
    <div>
      <p>Set the maximum number of trees you want to plant per month. This allows you to cap your monthly spend on planting trees.</p>
      <br/>
      <p><a href="https://thegoodapi.com/how-it-works">Learn more about our planting service</a></p>
    </div>
  )

  return (
    <Layout.AnnotatedSection id = "maximum planted"
    title = "Tree planting limit"
    description = {desc}>
      <LegacyCard sectioned  
       primaryFooterAction={{
         content:buttonCopy,
         loading: budgetLoading,
         onAction: updateBilling,
         disabled:disabled,
         icon:showPriorityIcon?<AiFillInfoCircle/>:false
        
       }}>
    
    <NumberInputSetting
         title="Limit the Maximum number of trees planted per month"
         body={`You will be charged $${costPerTree} per tree planted. If the maximum is reached, we will pause tree planting until the following month.`}
         val={maxTrees}
         handleChange={setMaxTrees}
         hint={hintText}
         err={parseInt(maxTrees) >= 0 ? "" : "Please make this greater than -1"}
         banner = {bannerToShow}
     />
    
      </LegacyCard>
   
      </Layout.AnnotatedSection>  
      )
}