import { useAppBridge } from "@shopify/app-bridge-react";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import {GetShopSettings, GetShopName, GetUpdateURLIfAvailable} from "../backend";
import React from "react";
import Onboarding from "./Onboarding";

export default function Crisp() {
    const shopify = useAppBridge();
    const httpFetch = authenticatedFetch(shopify);

    // load crisp
    React.useEffect(()=> {
        async function n() {
            const shopName = await GetShopName(httpFetch)
            console.log("Shop name is " + shopName)
            window.$crisp = [];
            window.CRISP_TOKEN_ID = shopName;
            window.CRISP_WEBSITE_ID = `${process.env.CRISP_WEBSITE_ID}`;
            (function () {
                var d = document;
                var s = d.createElement("script");
                s.src = "https://client.crisp.chat/l.js";
                s.async = 1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
            try {
                if (window.$crisp) {
                    window.$crisp.push(["set", "user:nickname", [shopName]]);
                }
            } catch (e) {
                console.log(e);
            }
        } 

       n()
    })
    
    return (
      <></>
    )
}