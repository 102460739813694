import { useAppBridge } from "@shopify/app-bridge-react";
import { Loading, Card,Text,VideoThumbnail,MediaCard,LegacyCard, Page, Grid, Layout, HorizontalStack, HorizontalGrid, Box, Button } from "@shopify/polaris";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import {GetShopSettings, GetShopName, GetUpdateURLIfAvailable, BASE_URL, GetShopAllowed} from "../backend";
import React from "react";
import Onboarding from "./Onboarding";
import Crisp from "./Crisp";

export default function Integrations() {
    const shopify = useAppBridge();
    const httpFetch = authenticatedFetch(shopify);
    
    const[shopName, setShopName] = React.useState("");
    const [allowed, setAllowed] = React.useState(true);
    React.useEffect(() => {
      // See if they are allowed first 
      GetShopAllowed(httpFetch).then((allowed) => {
            setAllowed(allowed)
        GetShopName(httpFetch).then((shopName) => {
          setShopName(shopName);
        });
      });
    }
    , []);

    return (
      <>
      {allowed ? (
     
    <Page fullWidth>
      <Crisp />
      <Layout>
        <Layout.Section>
        <Text variant="heading2xl" as="h1">Page Builders</Text>
        <br/>
      <Grid>
        <Grid.Cell columnSpan={{xs: 4, sm: 4, md: 4, lg: 4, xl: 4}}>
       <Card>
       <Box>
        <HorizontalStack blockAlign="center" gap="3">
          <img
        alt=""
        width="10%"
        height="10%"
        style={{
          objectFit: 'scale-down',
        }}
        src="https://sprout-trees.imgix.net/pagefly.png?fm=webp&auto=format&w=1000"
      />
      
         <Text style={{
         }}variant="headingLg" as="h1">PageFly Page Builder</Text>
         </HorizontalStack>
         <br/>
        <Text variant="bodyLg" as="p">
        Build high-converting landing pages with a powerful drag-and-drop system. No coding knowledge required.
        Drag and drop Sprout product and counter widgets and customize your online store with PageFly.<br /><br />

      </Text>

      <HorizontalStack align="end">
         <Button fullWidth={false} external="true" url={`${BASE_URL}/app/integrations/pf_redirect?shopName=${shopName}`} target="_blank" primary onClick={() => {
         } }>Learn More</Button>
         </HorizontalStack>

        </Box>
      
       </Card>
        </Grid.Cell>
      
      
      </Grid>
      </Layout.Section>

      </Layout>
    </Page>) : null}

      </>
     
    )
    
}