import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  createHashRouter,
  withRouter,
} from "react-router-dom";
import OauthConnect from "./Oauth";
import AppHome from "./AppHome";
import { verifyURL } from "../backend";
import Settings from "./Settings";
import { ClientRouter } from '@shopify/app-bridge-react';
import Integrations from "./Integrations";
import PlantMoreTrees from "./PlantMoreTrees";
import { GetShopAllowed, PostAdditionalTrees, GetTopupState } from "../backend";
import { useAppBridge, } from "@shopify/app-bridge-react"
import { authenticatedFetch,} from '@shopify/app-bridge-utils';


export default function Router(props) {
const basePath = "";
const { history } = props;
const shopify = useAppBridge();
const httpFetch = authenticatedFetch(shopify);
const [allowed, setAllowed] = React.useState(true);


const router = createBrowserRouter([
  {
    path: `${basePath}`,
    element: <AppHome />,
  },
  {
    path: `${basePath}/fe/home`,
    element: <AppHome />,
  },
  {
    path: `${basePath}/fe/plant_additional_trees`,
    element: <PlantMoreTrees />,
  },
  {
    path: `${basePath}/fe/integrations`,
    element: <Integrations />,
  },
]);

 React.useEffect(() => {
        GetShopAllowed(httpFetch).then((allowed) => {
            if (!allowed) {
                setAllowed(false);
            }
        })
    }, [])




return (
    <React.StrictMode>
      {allowed ? (
    <RouterProvider router={router} />
      ) : (
         null
      )}
    </React.StrictMode>
)
}