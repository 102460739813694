import { gray, green, red, yellow, teal, blue, orange, purple } from '../colors.mjs';
import { gray as gray$1, green as green$1, red as red$1, yellow as yellow$1, azure, orange as orange$1, purple as purple$1, blue as blue$1, magenta, cyan, rose } from '../colors-experimental.mjs';

var color = {
  'color-bg-inverse': {
    value: gray[900],
    valueExperimental: gray$1[16](),
    description: ''
  },
  'color-bg-inset-strong': {
    value: gray[800],
    valueExperimental: gray$1[15](),
    description: ''
  },
  'color-bg-inverse-hover': {
    value: gray[800],
    valueExperimental: gray$1[14](),
    description: ''
  },
  'color-bg-inverse-active': {
    value: gray[700],
    valueExperimental: gray$1[13](),
    description: ''
  },
  'color-bg-strong-hover': {
    value: gray[500],
    valueExperimental: gray$1[9](),
    description: ''
  },
  'color-bg-strong-active': {
    value: gray[500],
    valueExperimental: gray$1[10](),
    description: ''
  },
  'color-bg-strong': {
    value: gray[400],
    valueExperimental: gray$1[8](),
    description: ''
  },
  'color-bg-subdued-active': {
    value: gray[300],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-bg-disabled': {
    value: gray[300],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-bg-interactive-disabled': {
    value: gray[300],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-bg-app': {
    value: gray[200],
    valueExperimental: gray$1[6](),
    description: ''
  },
  'color-bg-app-active': {
    value: gray[400],
    description: ''
  },
  'color-bg-app-hover': {
    value: gray[300],
    valueExperimental: gray$1[2](),
    description: ''
  },
  'color-bg-app-selected': {
    value: gray[300],
    valueExperimental: gray$1[3](),
    description: ''
  },
  'color-bg-active': {
    value: gray[300],
    valueExperimental: gray$1[4](),
    description: ''
  },
  'color-bg-subdued-hover': {
    value: gray[200],
    valueExperimental: gray$1[6](),
    description: ''
  },
  'color-bg-inset': {
    value: gray[200],
    valueExperimental: gray$1[6](),
    description: ''
  },
  'color-bg-hover': {
    value: gray[200],
    valueExperimental: gray$1[3](),
    description: ''
  },
  'color-bg-subdued': {
    value: gray[100],
    valueExperimental: gray$1[4](),
    description: ''
  },
  'color-bg-input': {
    value: gray[50],
    valueExperimental: gray$1[1](),
    description: ''
  },
  'color-bg': {
    value: gray[50],
    valueExperimental: gray$1[1](),
    description: ''
  },
  'color-bg-primary-active': {
    value: green[900],
    valueExperimental: gray$1[16](),
    description: ''
  },
  'color-bg-primary-hover': {
    value: green[800],
    valueExperimental: gray$1[16](),
    description: ''
  },
  'color-bg-primary': {
    value: green[700],
    valueExperimental: gray$1[15](),
    description: ''
  },
  'color-bg-success-strong': {
    value: green[600],
    valueExperimental: green$1[12],
    description: ''
  },
  'color-bg-success': {
    value: green[300],
    valueExperimental: green$1[3],
    description: ''
  },
  'color-bg-primary-subdued-active': {
    value: green[200],
    valueExperimental: gray$1[6](),
    description: ''
  },
  'color-bg-success-subdued-active': {
    value: green[200],
    description: ''
  },
  'color-bg-success-subdued': {
    value: green[100],
    valueExperimental: green$1[3],
    description: ''
  },
  'color-bg-primary-subdued-hover': {
    value: green[100],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-bg-success-subdued-hover': {
    value: green[50],
    valueExperimental: green$1[5],
    description: ''
  },
  'color-bg-primary-subdued': {
    value: green[50],
    valueExperimental: gray$1[8](),
    description: ''
  },
  'color-bg-primary-subdued-selected': {
    value: green[50],
    valueExperimental: gray$1[6](),
    description: ''
  },
  'color-bg-critical-strong-active': {
    value: red[800],
    valueExperimental: red$1[14],
    description: ''
  },
  'color-bg-critical-strong-hover': {
    value: red[700],
    valueExperimental: red$1[13],
    description: ''
  },
  'color-bg-critical-strong': {
    value: red[600],
    valueExperimental: red$1[12],
    description: ''
  },
  'color-bg-critical-subdued-active': {
    value: red[200],
    valueExperimental: red$1[6],
    description: ''
  },
  'color-bg-critical': {
    value: red[200],
    valueExperimental: red$1[6],
    description: ''
  },
  'color-bg-critical-subdued': {
    value: red[100],
    valueExperimental: red$1[4],
    description: ''
  },
  'color-bg-critical-subdued-hover': {
    value: red[50],
    valueExperimental: red$1[5],
    description: ''
  },
  'color-bg-caution-strong': {
    value: yellow[600],
    valueExperimental: yellow$1[6],
    description: ''
  },
  'color-bg-caution': {
    value: yellow[300],
    valueExperimental: yellow$1[4],
    description: ''
  },
  'color-bg-caution-subdued-active': {
    value: yellow[200],
    valueExperimental: yellow$1[4],
    description: ''
  },
  'color-bg-caution-subdued': {
    value: yellow[100],
    valueExperimental: yellow$1[2],
    description: ''
  },
  'color-bg-caution-subdued-hover': {
    value: yellow[50],
    valueExperimental: yellow$1[3],
    description: ''
  },
  'color-bg-info-strong': {
    value: teal[600],
    valueExperimental: azure[9],
    description: ''
  },
  'color-bg-info-subdued-active': {
    value: teal[200],
    valueExperimental: azure[6],
    description: ''
  },
  'color-bg-info': {
    value: teal[200],
    valueExperimental: azure[4],
    description: ''
  },
  'color-bg-info-subdued': {
    value: teal[100],
    valueExperimental: azure[3],
    description: ''
  },
  'color-bg-info-subdued-hover': {
    value: teal[50],
    valueExperimental: azure[4],
    description: ''
  },
  'color-bg-interactive-active': {
    value: blue[800],
    valueExperimental: gray$1[14](),
    description: ''
  },
  'color-bg-interactive-hover': {
    value: blue[700],
    valueExperimental: gray$1[15](),
    description: ''
  },
  'color-bg-interactive': {
    value: blue[600],
    valueExperimental: gray$1[16](),
    description: ''
  },
  'color-bg-interactive-subdued-active': {
    value: blue[200],
    valueExperimental: gray$1[6](),
    description: ''
  },
  'color-bg-interactive-subdued-hover': {
    value: blue[100],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-bg-interactive-subdued': {
    value: blue[50],
    valueExperimental: gray$1[8](),
    description: ''
  },
  'color-bg-interactive-selected': {
    value: blue[50],
    valueExperimental: gray$1[6](),
    description: ''
  },
  'color-bg-warning': {
    value: orange[200],
    valueExperimental: orange$1[7],
    description: ''
  },
  'color-bg-magic-strong': {
    value: purple[500],
    valueExperimental: purple$1[12],
    description: ''
  },
  'color-bg-magic-hover': {
    value: purple[200],
    valueExperimental: purple$1[7],
    description: ''
  },
  'color-bg-magic-active': {
    value: purple[300],
    valueExperimental: purple$1[8],
    description: ''
  },
  'color-bg-magic': {
    value: purple[100],
    valueExperimental: purple$1[6],
    description: ''
  },
  'color-bg-magic-subdued-hover': {
    value: purple[100],
    valueExperimental: purple$1[4],
    description: ''
  },
  'color-bg-magic-subdued-active': {
    value: purple[200],
    valueExperimental: purple$1[6],
    description: ''
  },
  'color-bg-magic-subdued': {
    value: purple[50],
    valueExperimental: purple$1[3],
    description: ''
  },
  'color-border-input-hover': {
    value: gray[800],
    valueExperimental: gray$1[13](),
    description: ''
  },
  'color-border-inverse': {
    value: gray[800],
    valueExperimental: gray$1[13](),
    description: ''
  },
  'color-border-strong-hover': {
    value: gray[700],
    valueExperimental: gray$1[11](),
    description: ''
  },
  'color-border-input': {
    value: gray[600],
    valueExperimental: gray$1[12](),
    description: ''
  },
  'color-border-hover': {
    value: gray[600],
    valueExperimental: gray$1[10](),
    description: ''
  },
  'color-border-strong': {
    value: gray[600],
    valueExperimental: gray$1[10](),
    description: ''
  },
  'color-border': {
    value: gray[500],
    valueExperimental: gray$1[8](),
    description: ''
  },
  'color-border-disabled': {
    value: gray[400],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-border-subdued': {
    value: gray[400],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-border-interactive-disabled': {
    value: gray[400],
    valueExperimental: gray$1[7](),
    description: ''
  },
  'color-border-primary': {
    value: green[700],
    valueExperimental: gray$1[8](),
    description: ''
  },
  'color-border-success': {
    value: green[600],
    valueExperimental: green$1[5],
    description: ''
  },
  'color-border-success-subdued': {
    value: green[400],
    valueExperimental: green$1[5],
    description: ''
  },
  'color-border-critical-active': {
    value: red[900],
    valueExperimental: red$1[11],
    description: ''
  },
  'color-border-critical-hover': {
    value: red[800],
    valueExperimental: red$1[10],
    description: ''
  },
  'color-border-critical': {
    value: red[600],
    valueExperimental: red$1[9],
    description: ''
  },
  'color-border-critical-subdued': {
    value: red[400],
    valueExperimental: red$1[9],
    description: ''
  },
  'color-border-caution': {
    value: yellow[600],
    valueExperimental: yellow$1[5],
    description: ''
  },
  'color-border-caution-subdued': {
    value: yellow[400],
    valueExperimental: yellow$1[5],
    description: ''
  },
  'color-border-info': {
    value: teal[500],
    valueExperimental: azure[9],
    description: ''
  },
  'color-border-info-subdued': {
    value: teal[400],
    valueExperimental: azure[9],
    description: ''
  },
  'color-border-interactive-active': {
    value: blue[800],
    valueExperimental: blue$1[15],
    description: ''
  },
  'color-border-interactive-hover': {
    value: blue[700],
    valueExperimental: blue$1[14],
    description: ''
  },
  'color-border-interactive': {
    value: blue[500],
    valueExperimental: blue$1[13],
    description: ''
  },
  'color-border-interactive-focus': {
    value: blue[500],
    valueExperimental: blue$1[13],
    description: ''
  },
  'color-border-interactive-subdued': {
    value: blue[200],
    valueExperimental: blue$1[13],
    description: ''
  },
  'color-border-magic-strong': {
    value: purple[500],
    valueExperimental: purple$1[12],
    description: ''
  },
  'color-border-magic': {
    value: purple[400],
    valueExperimental: purple$1[10],
    description: ''
  },
  'color-icon-hover': {
    value: gray[900],
    valueExperimental: gray$1[15](),
    description: ''
  },
  'color-icon': {
    value: gray[800],
    valueExperimental: gray$1[14](),
    description: ''
  },
  'color-icon-active': {
    value: gray[900],
    description: ''
  },
  'color-icon-subdued': {
    value: gray[700],
    valueExperimental: gray$1[12](),
    description: ''
  },
  'color-icon-disabled': {
    value: gray[600],
    valueExperimental: gray$1[10](),
    description: ''
  },
  'color-icon-interactive-disabled': {
    value: gray[600],
    valueExperimental: gray$1[10](),
    description: ''
  },
  'color-icon-inverse': {
    value: gray[400],
    valueExperimental: gray$1[8](),
    description: ''
  },
  'color-icon-on-color': {
    value: gray[50],
    valueExperimental: gray$1[1](),
    description: ''
  },
  'color-icon-primary': {
    value: green[700],
    valueExperimental: gray$1[16](),
    description: ''
  },
  'color-icon-success': {
    value: green[600],
    valueExperimental: green$1[12],
    description: ''
  },
  'color-icon-critical': {
    value: red[600],
    valueExperimental: red$1[11],
    description: ''
  },
  'color-icon-caution': {
    value: yellow[700],
    valueExperimental: yellow$1[11],
    description: ''
  },
  'color-icon-info': {
    value: teal[600],
    valueExperimental: azure[11],
    description: ''
  },
  'color-icon-warning': {
    value: orange[500],
    valueExperimental: orange$1[11],
    description: ''
  },
  'color-icon-interactive-active': {
    value: blue[800],
    valueExperimental: blue$1[15],
    description: ''
  },
  'color-icon-interactive-hover': {
    value: blue[700],
    valueExperimental: blue$1[14],
    description: ''
  },
  'color-icon-interactive': {
    value: blue[600],
    valueExperimental: blue$1[13],
    description: ''
  },
  'color-icon-interactive-inverse': {
    value: blue[400],
    valueExperimental: blue$1[8],
    description: ''
  },
  'color-icon-magic': {
    value: purple[500],
    valueExperimental: purple$1[13],
    description: ''
  },
  'color-text': {
    value: gray[900],
    valueExperimental: gray$1[15](),
    description: ''
  },
  'color-text-subdued': {
    value: gray[800],
    valueExperimental: gray$1[13](),
    description: ''
  },
  'color-text-disabled': {
    value: gray[700],
    valueExperimental: gray$1[11](),
    description: ''
  },
  'color-text-interactive-disabled': {
    value: gray[700],
    valueExperimental: gray$1[10](),
    description: ''
  },
  'color-text-inverse-subdued': {
    value: gray[600],
    valueExperimental: gray$1[10](),
    description: ''
  },
  'color-text-inverse': {
    value: gray[200],
    valueExperimental: gray$1[8](),
    description: ''
  },
  'color-text-on-color': {
    value: gray[50],
    valueExperimental: gray$1[1](),
    description: ''
  },
  'color-text-success-strong': {
    value: green[900],
    valueExperimental: green$1[15],
    description: ''
  },
  'color-text-success': {
    value: green[700],
    valueExperimental: green$1[15],
    description: ''
  },
  'color-text-primary': {
    value: green[700],
    valueExperimental: gray$1[14](),
    description: ''
  },
  'color-text-primary-hover': {
    value: green[800],
    valueExperimental: gray$1[14](),
    description: ''
  },
  'color-text-critical-strong': {
    value: red[900],
    valueExperimental: red$1[14],
    description: ''
  },
  'color-text-critical-active': {
    value: red[800],
    valueExperimental: red$1[16],
    description: ''
  },
  'color-text-critical': {
    value: red[600],
    valueExperimental: red$1[14],
    description: ''
  },
  'color-text-caution-strong': {
    value: yellow[900],
    valueExperimental: yellow$1[15],
    description: ''
  },
  'color-text-caution': {
    value: yellow[800],
    valueExperimental: yellow$1[15],
    description: ''
  },
  'color-text-info-strong': {
    value: teal[900],
    valueExperimental: azure[16],
    description: ''
  },
  'color-text-info': {
    value: teal[700],
    valueExperimental: azure[14],
    description: ''
  },
  'color-text-warning-strong': {
    value: orange[900],
    valueExperimental: orange$1[16],
    description: ''
  },
  'color-text-interactive-active': {
    value: blue[800],
    valueExperimental: blue$1[15],
    description: ''
  },
  'color-text-interactive-hover': {
    value: blue[700],
    valueExperimental: blue$1[14],
    description: ''
  },
  'color-text-interactive': {
    value: blue[600],
    valueExperimental: blue$1[13],
    description: ''
  },
  'color-text-interactive-inverse': {
    value: blue[400],
    valueExperimental: blue$1[8],
    description: ''
  },
  'color-text-magic-strong': {
    value: purple[800],
    valueExperimental: purple$1[15],
    description: ''
  },
  'color-text-magic': {
    value: purple[600],
    valueExperimental: purple$1[14],
    description: ''
  },
  // Experimental tokens
  'color-bg-backdrop-experimental': {
    value: gray$1[16]('0.75'),
    description: ''
  },
  'color-bg-primary-disabled-experimental': {
    value: gray$1[9](),
    description: ''
  },
  'color-bg-secondary-experimental': {
    value: gray$1[5](),
    description: ''
  },
  'color-bg-input-hover-experimental': {
    value: gray$1[3](),
    description: ''
  },
  'color-border-input-active-experimental': {
    value: gray$1[16]()
  },
  'color-border-critical-strong-experimental': {
    value: red$1[14]
  },
  'color-bg-input-active-experimental': {
    value: gray$1[4](),
    description: ''
  },
  'color-bg-transparent-experimental': {
    value: gray$1[16]('0'),
    description: ''
  },
  'color-bg-transparent-subdued-experimental': {
    value: gray$1[16]('0.07'),
    description: ''
  },
  'color-bg-transparent-hover-experimental': {
    value: gray$1[16]('0.05'),
    description: ''
  },
  'color-bg-transparent-active-experimental': {
    value: gray$1[16]('0.07'),
    description: ''
  },
  'color-bg-transparent-disabled-experimental': {
    value: gray$1[16]('0.04'),
    description: ''
  },
  'color-bg-transparent-secondary-disabled-experimental': {
    value: gray$1[16]('0.08'),
    description: ''
  },
  'color-bg-transparent-primary-disabled-experimental': {
    value: gray$1[16]('0.18'),
    description: ''
  },
  'color-bg-transparent-primary-experimental': {
    value: gray$1[16]('0.60'),
    description: ''
  },
  'color-bg-success-strong-hover-experimental': {
    value: green$1[13],
    description: ''
  },
  'color-bg-success-strong-active-experimental': {
    value: green$1[14],
    description: ''
  },
  'color-bg-warning-subdued-experimental': {
    value: orange$1[3],
    description: ''
  },
  'color-bg-warning-strong-experimental': {
    value: orange$1[9],
    description: ''
  },
  'color-text-warning-experimental': {
    value: orange$1[15],
    description: ''
  },
  'color-text-critical-hover-experimental': {
    value: red$1[15],
    description: ''
  },
  'color-icon-info-strong-experimental': {
    value: azure[14],
    description: ''
  },
  'color-icon-warning-strong-experimental': {
    value: orange$1[13],
    description: ''
  },
  'color-icon-success-strong-experimental': {
    value: green$1[14],
    description: ''
  },
  'color-icon-critical-strong-experimental': {
    value: red$1[14],
    description: ''
  },
  'color-icon-critical-strong-hover-experimental': {
    value: red$1[15],
    description: ''
  },
  'color-icon-critical-strong-active-experimental': {
    value: red$1[16],
    description: ''
  },
  'color-avatar-background-experimental': {
    value: gray$1[11](),
    description: ''
  },
  'color-avatar-color-experimental': {
    value: gray$1[1](),
    description: ''
  },
  'color-avatar-style-one-background-experimental': {
    value: magenta[7],
    description: ''
  },
  'color-avatar-style-one-color-experimental': {
    value: magenta[14],
    description: ''
  },
  'color-avatar-style-two-background-experimental': {
    value: green$1[7],
    description: ''
  },
  'color-avatar-style-two-color-experimental': {
    value: green$1[14],
    description: ''
  },
  'color-avatar-style-three-background-experimental': {
    value: cyan[7],
    description: ''
  },
  'color-avatar-style-three-color-experimental': {
    value: cyan[14],
    description: ''
  },
  'color-avatar-style-four-background-experimental': {
    value: azure[7],
    description: ''
  },
  'color-avatar-style-four-color-experimental': {
    value: azure[14],
    description: ''
  },
  'color-avatar-style-five-background-experimental': {
    value: rose[7],
    description: ''
  },
  'color-avatar-style-five-color-experimental': {
    value: rose[14],
    description: ''
  }
};

export { color };
