{
  "name": "@shopify/app-bridge-react",
  "version": "3.7.9",
  "types": "index.d.ts",
  "main": "index.js",
  "unpkg": "umd/index.js",
  "jsdelivr": "umd/index.js",
  "files": [
    "/components/",
    "/umd/",
    "/hooks/",
    "/utilities/",
    "/context.d.ts",
    "/context.js",
    "/index.d.ts",
    "/index.js",
    "/types.d.ts",
    "/types.js",
    "/useAppBridge.d.ts",
    "/useAppBridge.js"
  ],
  "private": false,
  "publishConfig": {
    "access": "public",
    "@shopify:registry": "https://registry.npmjs.org"
  },
  "repository": "git@github.com:Shopify/app-bridge.git",
  "homepage": "https://shopify.dev/docs/api/app-bridge/previous-versions/app-bridge-from-npm/using-react",
  "author": "Shopify Inc.",
  "license": "MIT",
  "scripts": {
    "build": "yarn build:tsc && yarn build:umd",
    "build:tsc": "NODE_ENV=production tsc",
    "build:umd": "NODE_ENV=production webpack -p",
    "check": "tsc",
    "clean": "yarn clean:tsc && yarn clean:umd",
    "clean:tsc": "NODE_ENV=production tsc --build --clean",
    "clean:umd": "rm -rf ./umd",
    "pack": "yarn pack",
    "size": "size-limit"
  },
  "sideEffects": false,
  "size-limit": [
    {
      "limit": "36.4 KB",
      "path": "index.js"
    }
  ],
  "dependencies": {
    "@shopify/app-bridge": "^3.7.9"
  },
  "devDependencies": {
    "@shopify/app-bridge-testing-library": "^0.0.4",
    "@shopify/react-testing": "^4.1.1",
    "@types/react": "^18.0.2",
    "react-dom": "^18.2.0"
  },
  "peerDependencies": {
    "react": "^16.0.0 || ^17.0.0 || ^18.0.0"
  }
}
