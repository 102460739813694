import { createRoot } from "react-dom/client";
import  EntryPoint  from "./components/EntryPoint"; 
import Router from "./components/Router";
import AppHome from "./components/AppHome";

const container = document.getElementById("app");
const root = createRoot(container)




root.render(<EntryPoint />);