import { LegacyCard,Text,TextField, ChoiceList,Banner, Button, Layout, Page, Stack, Toast, Frame, ButtonGroup, Form, FormLayout, TextContainer, ProgressBar, Select, Card, Checkbox } from "@shopify/polaris"
import React from "react"
import NumberInputSetting from "./numberinputsetting"
import { useAppBridge, } from "@shopify/app-bridge-react"
import { authenticatedFetch,} from '@shopify/app-bridge-utils';
import { BASE_URL, isProd } from "../backend"
import { Redirect} from '@shopify/app-bridge/actions';
import Instructions from "./instructions"
import BudgetSettings from "./budgetSettings";
import CustomerPlantingSection from "./customerplanting";
import Metrics from "./metrics";
import Crisp from "./Crisp";
  
 

export default function Settings({ currentSavedSettings }) {
        return (
          <Page>
            <Layout>
              <Layout.Section>
                <Metrics />
              </Layout.Section>
              <Layout.Section style={{
                    "paddingTop":"5%",
              }}>
                <div style={{
                    "position":"relative",
                    "top":"35px",
                }}>
                <Text variant="headingMd" as="h1">App settings</Text>
                
                </div>
              </Layout.Section>
              <Layout.Section>
                <AppSettings currentSavedSettings={currentSavedSettings} />
              </Layout.Section>
            </Layout>
          </Page>
        );
}

export function AppSettings({ currentSavedSettings }) {
    const warning = (
               <Banner
            title="Set Tree Limit"
            status="warning">
            <p> Your current limit is 0. No Trees will be planted. Please update your limit and approve it when shopify billing page loads. </p>
         </Banner>
    )
    const [budgetNotSetWarning, setBudgetNotSetWarning] = React.useState(warning)
    const [wasClicked, setWasClicked] = React.useState(false)
    const [maxTrees, setMaxTrees] = React.useState(currentSavedSettings.maxTrees)
    const [valueThreshold, setValueThreshold] = React.useState(currentSavedSettings.threshold)
    const [treesPerProd, setTreesPerProd] = React.useState(currentSavedSettings.numTrees)
    const [loading, setLoading] = React.useState(false)
    const [budgetLoading, setBudgetLoading] = React.useState(false)
    const [toastState, setToastState] = React.useState({
        state: false,
        message: ""
    })
    const [settingsNeedUpdating, setSettingsNeedUpdating] = React.useState(true)
    const shopify = useAppBridge()
    const httpFetch = isProd() ? authenticatedFetch(shopify) : fetch
    const redirect = Redirect.create(shopify)
    const toggleToastState = React.useCallback(
        (msg, state) => setToastState({
            state: state,
            message: msg,
        }),
        []
    )

    const [checked, setChecked] = React.useState(false);
     const handleCheckChange = React.useCallback(function(newCheck) {
         setChecked(newCheck)
         setWasClicked(true)
         setValueThreshold("0")
     },[])



     const [customerPlantingSettingsNeedUpdating, setCustomerPlantingSettingsNeedUpdating] = React.useState(true)
     const [customerPlantingSaveWasClicked, setCustomerPlantingSaveWasClicked] = React.useState(false)
     const [customerPlantingCheckbox, setCustomerPlantingCheckbox] = React.useState(false);
     const handleCustomerPlantingCheckbox = React.useCallback(function(newCheck) {
        setCustomerPlantingCheckbox(newCheck)
        setCustomerPlantingSaveWasClicked(true)
     })

    const toastMarkup = toastState.state ? (
        <Frame>
            < Toast content={toastState.message} duration={5000} onDismiss={
                toggleToastState
            } />
        </Frame>
    ) : null;

    React.useEffect(() => {
        setSelected([currentSavedSettings.state])
        setMaxTrees(currentSavedSettings.maxTrees)
        setTreesPerProd(currentSavedSettings.numTrees)
        setValueThreshold(currentSavedSettings.threshold)
        setCustomerPlantingCheckbox(currentSavedSettings.customerPlantingEnabled)
        if (currentSavedSettings.maxTrees > 0) {
            setBudgetNotSetWarning("")
        }

        if ((currentSavedSettings.threshold >= 1)  && currentSavedSettings.state == "per_order_threshold") {
            
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [currentSavedSettings])


    const updateBilling = async () => {
        setBudgetLoading(true)
        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                maxTrees: parseInt(maxTrees),
            })
        };


        const resp = await httpFetch(`${BASE_URL}/app/authorize_billing`, requestOptions)
        const json = await resp.json()

        if (parseInt(maxTrees) == 0) {
            setBudgetNotSetWarning(warning)
            setBannerToShow(warning)
        }
        if (json.confirmation_url == "") {
            if (json.status == "Application Disabled") {
                currentSavedSettings.maxTrees = 0
            }
            toggleToastState(json.status, true)
            setBudgetLoading(false)
            return
        }

        redirect.dispatch(Redirect.Action.REMOTE, {
            url: json.confirmation_url,
        });

        toggleToastState(json.status, true)
        setBudgetLoading(false)
    }


    const updateSettings = async () => {
        setLoading(true)
        var state = selected[0]
        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                state: state,
                treesPerProd: parseInt(treesPerProd),
                valueThreshold: parseFloat(valueThreshold),
            })
        };
        const resp = await httpFetch(`${BASE_URL}/app/update_settings`, requestOptions)
        setLoading(false)
        if (resp.status == 200) {
            toggleToastState("Settings Updated", true)
        
            currentSavedSettings.valueThreshold = valueThreshold
            if (!checked) {
                currentSavedSettings.valueThreshold=0
            }
            currentSavedSettings.treesPerProd = treesPerProd
            currentSavedSettings.state = state
            setSettingsNeedUpdating(false)
        } else {
            toggleToastState("Error Try Again", true)
            setSettingsNeedUpdating(true)
        }
    }

    const badgeTheme2Content = () => {
        return (
            <div>
                <p>If you are using a theme which supports app extensions then integrating this badge will be super easy!</p>
                <p><b>VIDEO:</b> If you want to watch a quick video of the instructions, <a target="_blank" rel="noopener noreferrer" href="https://www.loom.com/share/02cc21c02b9446ba850f83b39e8708c6">Click here</a> Or, you can follow the step by step instructions below </p>
                <ol>
                    <li>Head over to your Theme Editor or <a target="_blank" rel="noopener noreferrer" href={currentSavedSettings.themeEditorUrl}>Click here</a> to open you theme editor</li>
                    <li>Choose your Product Template at the top</li>
                    <li>On the left Hand side, under Product Information, click on Add Block</li>
                    <li>Under Apps, choose Sprout Product Badge</li>
                    <li>If you do not see Add Block as an option, you can click add "section" and add the Sprout Product Badge under section > apps > Sprout Product Badge</li>
                    <li>Move the badge where you see fit and you are done!</li>
                    <li>If you run into any issues at all, please reach out to our Co-Founder Saif at saif@thegoodapi.com or from the Get Support Button on the apps pages and we'll get you setup in no time =) </li>
                </ol>
            </div>
        )
    }

    const badgeTheme1Content = () => {
        return (
            <div>
                <p><b>VIDEO:</b> If you want to watch a quick video of the instructions, <a target="_blank" rel="noopener noreferrer" href="https://www.loom.com/share/6dbd355081b540388be5fdaf010d461f">Click here</a> Or, you can follow the step by step instructions below </p>
                <ol>
                    <li>Go into your theme code editer <a target="_parent" rel="noopener noreferrer" href={currentSavedSettings.themeCodeEditorUrl}>here</a> and you should see product files such as sections/main-product.liquid. This is the file that renders your product page. Open this file</li>
                    <li>Inside this file, you can add the badge wherever you'd like. For example, if you'd like to add it under the payment button, find where it says &#123;&#123;form | payment_button&#125;&#125;</li>
                    <li>Once you have found your spot, add the following line &lt;div id=&quot;SproutProductBadgeVintage&quot; class=&quot;SproutProductBadgeVintage&quot;&gt;&lt;/div&gt;</li>
                    <li>View your page to see your changes and you are done! </li>
                    <li>NOTE: These instructions were written for the theme DAWN but file names can vary theme to theme. If you need any help at all, please feel free to reach out to Saif (Our Co-Founder) at saif@thegoodapi.com or from the Get Support Button from the apps page and we'll be happy to install the badge in your theme :)</li>
                </ol>
            </div>
        )
    }

    const cartTheme2Content = () => {
        return (
            <div>
                <p>If you are using a theme which supports app extensions then integrating this badge will be super easy!</p>
                <p><b>VIDEO:</b> If you want to watch a quick video of the instructions, <a target="_blank" rel="noopener noreferrer" href="https://www.loom.com/share/5c3ecb400362419c99f929e7ff0194ca">Click here</a> Or, you can follow the step by step instructions below </p>
                <ol>
                    <li>Head over to your Theme Editor or <a target="_blank" rel="noopener noreferrer" href={currentSavedSettings.themeEditorUrl}>Click here</a> to open you theme editor</li>
                    <li>Choose your Cart Template at the top</li>
                    <li>On the left Hand side, under Apps, click on Add Block</li>
                    <li>Under Apps, choose Sprout Cart Badge</li>
                    <li>If you do not see Add Block as an option, you can click add "section" and add the Sprout Cart Badge under section > apps > Sprout Cart Badge</li>
                    <li>Move the badge where you see fit and you are done!</li>
                    <li>If you run into any issues at all, please reach out to our Co-Founder Saif at saif@thegoodapi.com or from the Get Support Button on the apps pages and we'll get you setup in no time =) </li>
                </ol>

                    <Banner
                    title="Modal and Sliding Carts"
                    status="warning"
                >
                    <p> Modal and Sliding carts are a tiny bit more involved to install on your own. Feel free to send us an email at saif@thegoodapi.com or from the Get Support Button from the apps page and we'll get it installed for you =) </p>
                </Banner>
            </div>
        )
    }

    const cartTheme1Content = () => {
        return (
            <div>
                <p><b>VIDEO:</b> If you want to watch a quick video of the instructions, <a target="_blank" rel="noopener noreferrer" href="https://www.loom.com/share/95005ec45d6c47a48a907939ded4a12f">Click here</a> Or, you can follow the step by step instructions below </p>
                <ol>
                    <li>Go into your theme code editer <a target="_parent" rel="noopener noreferrer" href={currentSavedSettings.themeCodeEditorUrl}>here</a> and you should see cart files such as sections/cart-template.liquid. This is the file that renders your cart page. Open this file</li>
                    <li>Inside this file, you can add the badge wherever you'd like. For example, if you'd like to add it just above your cart items table, find where the &lt;table&gt; tag starts. </li>
                    <li>Once you have found your spot, add the following line &lt;div id=&quot;SproutCartBadgeVintage&quot; class=&quot;SproutCartBadgeVintage&quot;&gt;&lt;/div&gt;. If you are wanting to put this above the table, insert this above the &lt;table&gt; block starts </li>
                    <li>View your page to see your changes and you are done! </li>
                    <li>Note 1: These instructions were written for the theme DAWN but file names can vary theme to theme. If you need any help at all, please feel free to reach out to Saif (Our Co-Founder) at saif@thegoodapi.com or from the Get Support Button from the apps page and we'll be happy to install the badge in your theme :)</li>
                    <li>Note 2: Installing the badge on a "sliding"/"drawer" cart is more involved and so we'd love to do it for you - please feel free to reach out to Saif (Our Co-Founder) at saif@thegoodapi.com or from the Get Support Button from the apps page and we'll be happy to install the badge in your theme :)</li>
                </ol>
                <Banner
                    title="Modal and Sliding Carts"
                    status="info"
                >
                    <p> Modal and Sliding carts are a tiny bit more involved to install on your own. Feel free to send us an email at saif@thegoodapi.com or from the Get Support Button from the apps page and we'll get it installed for you =) </p>
                </Banner>
            </div>
        )
    }


    const [selected, setSelected] = React.useState([currentSavedSettings.state]);
    const handleSelectChange = React.useCallback((value) => setSelected(value), []);

    React.useEffect(() => {
        async function n() {
        if (selected[0] != currentSavedSettings['state']) {
            setSettingsNeedUpdating(true)
            return
        }

        if (selected[0] == "per_product") {
            if (treesPerProd != currentSavedSettings["numTrees"]) {
                setSettingsNeedUpdating(true)
                return
            } else {
                setSettingsNeedUpdating(false)
                return
            }
        }

        if (selected[0] == "per_order_threshold") {
            if (valueThreshold != currentSavedSettings['threshold']) {
                setSettingsNeedUpdating(true)
            } else if (treesPerProd != currentSavedSettings['numTrees']) {
                setSettingsNeedUpdating(true)
             } else if (wasClicked) {
                setSettingsNeedUpdating(true)
            } else {
                setSettingsNeedUpdating(false)
            }
        }

        if (currentSavedSettings['customerPlantingEnabled'] != customerPlantingCheckbox) {
            setCustomerPlantingSettingsNeedUpdating(true)
        } else {
            setCustomerPlantingSettingsNeedUpdating(false)
        }
    }
    n()
    }, [selected, valueThreshold, treesPerProd, wasClicked, customerPlantingCheckbox])









    const renderOptionSettings = React.useCallback(
        (isSelected) => {
            if (isSelected) {
                switch (selected[0]) {
              
                    case "per_order_threshold": {
                        let inp = (
                            <div style={{
                                paddingRight:"1px",
                            }}>
                                     <Checkbox 
                            label="Set minimum order value (optional)"
                            checked={checked}                            
                            onChange={handleCheckChange} />
                <br/>   
                <br/>
                                         <TextField
                                    style={{
                                        paddingRight:"20%",
                                    }}
                                    min={1}
                                    minLength={1}
                                    pattern=""
                                    requiredIndicator={true}
                                    inputMode="numeric"
                                    label={`Only plant trees when order exceeds ($${currentSavedSettings.defaultCurrencyCode})`}
                                    value={valueThreshold}
                                    onChange={setValueThreshold}
                                    error={parseFloat(valueThreshold) >= 1 ? "" : "Please make this greater than 0"}
                                    helpText="Symbol and price will localize if you are using Shopify Markets, Shopify Geolocation app or any app that lets the user 'checkout' in their local currency"
                                />
                                
                                <br></br>
                                
                            </div>
                    )
                        
                        return (
                            <div style={{
                                paddingRight:"20px",
                            }}>
                            <TextField
                               style={{
                                paddingRight:"20%",
                            }}
                                min={1}
                                minLength={1}
                                pattern="^[1-9]\d*"
                                requiredIndicator={true}
                                inputMode="numeric"
                                label="Set the number of trees to plant with each order"
                                value={treesPerProd}
                                onChange={setTreesPerProd}
                                error={parseInt(treesPerProd) >= 1 ? "" : "Please make this greater than 0"}
                            />
                            <br></br>
                             
                            {checked ? inp: ( <div>  <Checkbox 
                            label="Set minimum order value (optional)"
                            checked={checked}                            
                            onChange={handleCheckChange} />
                <br/>  </div> )}
                             
                        </div>
                        )
                    }
                    case "per_product": {
                        return (
                            <div style={{
                                paddingRight:"20px",
                            }}>
                                <TextField
                                   style={{
                                    paddingRight:"2%",
                                }}
                                    min={1}
                                    minLength={1}
                                    pattern="^[1-9]\d*"
                                    requiredIndicator={true}
                                    inputMode="numeric"
                                    label="Set the number of trees to plant per product sold"
                                    value={treesPerProd}
                                    onChange={setTreesPerProd}
                                    error={parseInt(treesPerProd) >= 1 ? "" : "Please make this greater than 0"}
                                />
                                <br></br>
                            </div>
                        )
                    }

                    case "per_order_threshold_old": {
                        return (
                            <div style={{
                                paddingRight:"20px",
                            }}>
                                <br></br>
                                <TextField
                                    style={{
                                        paddingRight:"20%",
                                    }}
                                    min={1}
                                    minLength={1}
                                    pattern="^[1-9]\d*"
                                    requiredIndicator={true}
                                    inputMode="numeric"
                                    label={`Set the minimum order value before trees should be planted in ${currentSavedSettings.defaultCurrencyCode}`}
                                    value={valueThreshold}
                                    onChange={setValueThreshold}
                                    error={parseInt(valueThreshold) >= 1 ? "" : "Please make this greater than 0"}
                                    helpText="Symbol and Price will localize if you are using Shopify Markets, Shopify Geolocation app or any app that lets the user 'checkout' in their local currency"
                                />
                                
                                <br></br>
                                <TextField
                                
                                    min={1}
                                    minLength={1}
                                    pattern="^[1-9]\d*"
                                    requiredIndicator={true}
                                    inputMode="numeric"
                                    label="Set the number of trees to plant when minimum order value is reached"
                                    value={treesPerProd}
                                    onChange={setTreesPerProd}
                                    error={parseInt(treesPerProd) >= 1 ? "" : "Please make this greater than 0"}

                                />
                                <br></br>
                            </div>
                        )
                    }
                }
            }
        },
        [selected, treesPerProd, valueThreshold, checked]
    )


    const bannerContentBlock = () => {
        return (
            <div>
                <p><b>VIDEO:</b> If you want to watch a quick video of the instructions, <a target="_blank" rel = "noopener noreferrer" href="https://www.loom.com/share/1db8a33b565c401c90a2fc801219010b">Click here</a> Or, you can follow the step by step instructions below </p>
                <ol>
                    <li>Head over to your Theme Editor or <a target="_blank" rel = "noopener noreferrer" href={currentSavedSettings.themeEditorUrl}>Click here</a> to open you theme editor</li>
                    <li>Choose the page you'd like to install the banner on (typically Home Page)</li>
                    <li>On the left Hand side, click Add Section</li>
                    <li>Scroll down to the apps and click Sprout Impact Banner</li>
                    <li>You'll see text show up reading "Sprout Total Count Banner Will Appear Here After Save". Click Save and this will get updated with the Banner :)</li>
                    <li>Move around as needed</li>
                    <li>As always if you need any help installing this, please reach out from the app support or directly to Saif(Co-Founder) @ saif@thegoodapi.com and we'll be happy to install it for you</li>
                    <li>Note: This banner live updates as trees are planted</li>
                </ol>
            </div>
        )
    }

    const code = `
    <div class="{% if section.settings.include_padding %}page-width{% endif %}">
        <div id="SproutTreeCountVintage" class="SproutTreeCountVintage"> </div>
    </div>
    
    {% schema %}
      {
        "name": "SproutTreeCountVintage",
        "presets": [
          {
            "name": "SproutTreeCountVintage",
            "category": "Custom"
          }
        ],
        "settings": [
          {
            "type": "checkbox",
            "id": "include_padding",
            "default": true,
            "label": "Make section margins the same as theme"
          }
        ]
      }
    {% endschema %}
    `
    const bannerContent = () => {
        return (
            <div>
                <p><b>VIDEO:</b> If you want to watch a quick video of the instructions, <a target="_blank" rel = "noopener noreferrer" href="https://www.loom.com/share/1db8a33b565c401c90a2fc801219010b">Click here</a> Or, you can follow the step by step instructions below </p>
                <ol>
                    <li>Go into your theme code editer <a target="_parent" rel="noopener noreferrer" href={currentSavedSettings.themeCodeEditorUrl}>here</a> and head into the sections folder</li>
                    <li>Under the sections folder, add a new section called SproutTreeCountVintage. Choose "liquid" when it asks for the type of section you are create</li>
                    <li>Delete any prefilled code in the new file and copy and paste the code below into the new section</li>
                    <li><small>{code}</small></li>
                    <li>Head over to your Theme Editor or <a target="_blank" rel = "noopener noreferrer" href={currentSavedSettings.themeEditorUrl}>Click here</a> to open you theme editor</li>
                    <li>Choose the page you'd like to install the banner on (typically Home Page)</li>
                    <li>On the left Hand side, click Add Section</li>
                    <li>Click the Section "SproutTreeCountVintage"</li>
                    <li>Hit Save, and you will see the counter load</li>
                    <li>Move around as needed</li>
                    <li>As always if you need any help installing this, please reach out from the app support or directly to Saif(Co-Founder) @ saif@thegoodapi.com and we'll be happy to install it for you</li>
                    <li>Note: This banner live updates as trees are planted</li>
                </ol>
            </div>
        )
    }




  
    const block = `
    <div id="SproutTreeCountVintage" class="SproutTreeCountVintage">Banner will appear here after save</div>
    `
     
   

    const bannerContentBlog = () => {
        return (
            <div>
                <p><b>VIDEO:</b> If you want to watch a quick video of the instructions, <a target="_blank" rel = "noopener noreferrer" href="https://www.loom.com/share/07dab5452452408895abb4c6aed16e37">Click here</a> Or, you can follow the step by step instructions below </p>
                <ol>
                    <li>Head into the blog post you'd like to add the counter to</li>
                    <li> Click in to the top right "show HTML" (the icon that looks like &lt;&gt;) </li>
                    <li> Find the location where you would like to insert the banner</li>
                    <li>Copy paste the block below (under Show Snippet)</li>
                    <li>Click Save and now you should see the badge when you "view" page</li>
                    <li>As always, please reach out from support or directly to our Co-Founder Saif at saif@thegoodapi.com and we'll be happy to add it for you :)</li>
                    <br/>
                </ol>
                <Instructions buttonLabel={"Show Snippet"} content={[{
                        heading:"Code",
                        details: <small>{block}</small>,
                    }]}/>
            </div>
        )
    }
    
    const [treeCountBanner, setTreeCountBanner] = React.useState((<div></div>))
    React.useEffect(() => {
        async function n() {
        const requestOptions = {
            crossDomain: true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const resp = await httpFetch(`${BASE_URL}/app/badges/tree_count?shop=${currentSavedSettings.shopName}&admin=1`, requestOptions)

        const text = await resp.text()
        setTreeCountBanner(<div dangerouslySetInnerHTML={{ __html: text }} />)

    }
    n()
}, [])

    React.useEffect( () => {
          // Scripts for showing modal

          var script = document.createElement("link");
          script.setAttribute("rel", "stylesheet")
          script.setAttribute("href", "https://cdn.jsdelivr.net/npm/dialog-polyfill@0.5.6/dist/dialog-polyfill.css")
          document.body.appendChild(script)
  
          var script = document.createElement('script');
          script.setAttribute(
              'src',
              `https://cdn.jsdelivr.net/npm/dialog-polyfill@0.5.6/dist/dialog-polyfill.js`,
            );
          document.body.appendChild(script)
  
          var script = document.createElement('script');
          script.setAttribute(
              'src',
              `${BASE_URL}/app/badges/product_modal_script`,
            );
          document.body.appendChild(script)



            var script = document.createElement('script');
            script.setAttribute(
                'src',
                `${BASE_URL}/fe/topup_script`,
                );
            document.body.appendChild(script)

    }, [])

    const [productBadge, setProductBadge] = React.useState((<div></div>))
    React.useEffect(() => {

        async function n() {
        const requestOptions = {
            crossDomain: true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const resp = await httpFetch(`${BASE_URL}/app/badges/product?state=${selected[0]}&numTrees=${treesPerProd}&threshold=${valueThreshold}&shop=${currentSavedSettings.shopName}&currency_symbol=${currentSavedSettings.defaultCurrencySymbol}&admin=1&currency_code=${currentSavedSettings.defaultCurrencyCode}`, requestOptions)

        const text = await resp.text()
        setProductBadge(<div dangerouslySetInnerHTML={{ __html: text }} />)
    }
    n()

      

    }, [selected, treesPerProd, valueThreshold])
    const [cartBadgeVal, setUpdateCartBadge] = React.useState(currentSavedSettings.customerPlantingEnabled)

    const [cartBadge, setCartBadge] = React.useState((<div></div>))
    // load cart badge (for now hits server)
    React.useEffect(() => {

        async function n() {
        const requestOptions = {
            crossDomain: true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
         
        // get the css 
        async function addStylesheet(url) {
            const baseID = "SproutBaseStylesheet"
             var resp =  await fetch(url)
             var t = await resp.text()
              // Check if the stylesheet is already loaded
              var isLoaded = false;
              var links = document.getElementsByTagName('link');
              for (var i = 0; i < links.length; i++) {
                if (links[i].id === baseID) {
                  isLoaded = true;
                  break;
                }
              }
            
              // If the stylesheet is not loaded, add it to the document head
              if (!isLoaded) {
                var link = document.createElement('style');
                link.innerHTML = t
                link.id = baseID
                document.head.appendChild(link);
              }
            }
            addStylesheet(`${BASE_URL}/app/assets/css/badges/base`);



        const resp = await httpFetch(`${BASE_URL}/app/badges/cart?state=${selected[0]}&numTrees=${treesPerProd}&threshold=${valueThreshold}&shop=${currentSavedSettings.shopName}&admin=1&showCustomerPlanting=${cartBadgeVal}`, requestOptions)

        const text = await resp.text()
        setCartBadge(<div dangerouslySetInnerHTML={{ __html: text }} />)
        }
        n()

    }, [selected, treesPerProd, valueThreshold, customerPlantingCheckbox, customerPlantingSettingsNeedUpdating, customerPlantingSaveWasClicked, cartBadgeVal])

    
    const [bannerToShow, setBannerToShow] = React.useState("")
    React.useEffect(() => {
        switch(currentSavedSettings.appStatus) {
            case "inactive":
              setBannerToShow(        <Banner
                title="Set Tree Limit"
                status="warning">
                <p> Your current limit is 0. No Trees will be planted. Please update your limit and approve it when shopify billing page loads. </p>
             </Banner>
              )
              return
            case "limit_reached":
              setBannerToShow(        <Banner
                title=""
                status="warning">
                <p> Planting limit has been reached. Tree planting paused. Please consider increasing the limit to continue planting. </p>
             </Banner>
              )
              return
            default:
             setBannerToShow("")
          }
    }, [maxTrees])


    const [disableSubmit, setDisableSubmit] = React.useState(false)
    React.useEffect(() => {
        if (!settingsNeedUpdating) {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
    }, [maxTrees, treesPerProd, valueThreshold, settingsNeedUpdating])



    const [disableCPSubmit, setDisableCPSubmit] = React.useState(false)
    React.useEffect(() => {
        if (!customerPlantingSettingsNeedUpdating) {
            setDisableCPSubmit(true)
        } else {
            setDisableCPSubmit(false)
        }
    }, [customerPlantingCheckbox])

 
    return (
        <div>
            <Crisp/>
            <Form onSubmit={updateSettings}>
                <FormLayout>
                    <Layout>
                        <Layout.Section>
                            {/* {budgetNotSetWarning} */}
                        </Layout.Section>
                        {/* Includes a layout.section */}
                        <BudgetSettings maxTrees={maxTrees} setMaxTrees={setMaxTrees} budgetLoading={budgetLoading} updateBilling={updateBilling} buttonCopy={"Set Tree Limit"} disabled={maxTrees!=0 && currentSavedSettings.maxTrees==maxTrees} showPriorityIcon={currentSavedSettings.maxTrees == 0 }  refreshBudgetDate={currentSavedSettings.refreshBudgetDate} costPerTree={currentSavedSettings.costPerTree} bannerToShow={bannerToShow} />
                        <Layout.Section>

                            <Layout>
                                <Layout.AnnotatedSection id="Select Tree Planting" title="When do you want to plant trees"
                                 description='You can choose to plant trees on every product sold, or when the order reaches your minimum order value.'>
                                    <LegacyCard title="When to plant Trees" sectiond
                                        primaryFooterAction={{
                                            content: settingsNeedUpdating ? "Save Settings" : "Saved",
                                            loading: loading,
                                            onAction: updateSettings,
                                            disabled: disableSubmit,
                                            loading: loading,


                                        }}
                                    >   
                                    <br></br>
                                        <div style={{paddingLeft:"20px"}}> 
                                        <ChoiceList
                                            title= ""
                                            choices={[
                                                {
                                                    label: "Plant trees for every product sold",
                                                    value: "per_product",
                                                    helpText: "e.g plant 1 tree for every *quantity* of product sold",
                                                    renderChildren: renderOptionSettings
                                                },
                                                {
                                                    label: "Plant trees for every order",
                                                    value: "per_order_threshold",
                                                    helpText: "e.g plant 1 tree when the order is above $10",
                                                    renderChildren: renderOptionSettings,
                                                },

                                            ]}
                                            selected={selected}
                                            onChange={handleSelectChange}
                                        >
                                        </ChoiceList>
                                        <br></br>
                                        </div>

                                    </LegacyCard>
                                </Layout.AnnotatedSection>
                            </Layout>
                         </Layout.Section>   

                         <Layout.Section>


<CustomerPlantingSection enabled={currentSavedSettings.showTopupFeature} initCheckboxState={currentSavedSettings.customerPlantingEnabled} setCartBadge={setCartBadge} selected={selected[0]} treesPerProd={treesPerProd} valueThreshold={valueThreshold} shopName={currentSavedSettings.shopName} updateCartBadge={setUpdateCartBadge} cartBadgeVal={cartBadgeVal} setToast={toggleToastState}></CustomerPlantingSection>
</Layout.Section>              
                    </Layout>
                            
                </FormLayout>
            </Form>
            <div  style={{
                position: "relative",
                right:"20px",
                width:"102%",
            }}>
            <Layout>
                       <Layout.Section>
                        <div style={
                            {
                                position: "relative",
                                "top":"35px",
                            }
                        }>
                        <Layout.AnnotatedSection title="Install Marketing Assets">
                            {/* {budgetNotSetWarning} */}
                             </Layout.AnnotatedSection>
                             </div>
                        <Layout.AnnotatedSection
                        title="Product Badge"
                        description={ 
                        <div>
                            <p>This can be added to your product page</p>
                            <br/>
                            <strong>Want a different language?</strong>
                            <p>Please email us the details of the updated copy at saif@thegoodapi.com and we'll enable it for you.</p>
                        </div>}
                            >
                           <Card>
                        <div style={{
                                "max-width": "100%",
                                "padding-top":"5%",
                                "padding-left":"5%",
                                "padding-right":"5%",
                            }}>
                            {productBadge}
                            </div>
                            <br></br>

                            <Instructions buttonLabel={"View Installation Instructions"}  content={
                                [
                                    {
                                        heading:"Installation instructions for your current theme",
                                        details: currentSavedSettings.productBlock? badgeTheme2Content():badgeTheme1Content(),
                                    },
                                ]
                            } disabled={currentSavedSettings.maxTrees==0} />

                                    </Card>  
                        </Layout.AnnotatedSection>

                        <Layout.AnnotatedSection
                        title="Cart Badge"
                        description={ 
                            <div>
                                <p>This can be added to your product page</p>
                                <br/>
                                <strong>Want a different language?</strong>
                                <p>Please email us the details of the updated copy at saif@thegoodapi.com and we'll enable it for you.</p>
                            </div>}>
                            <Card>
                        <div style={{
                                "max-width": "100%",
                                "padding-top":"5%",
                                "padding-left":"5%",
                                "padding-right":"5%",
                            }}>
                            {cartBadge}
                            </div>
                            <br></br>
                            <Instructions buttonLabel={"View Installation Instructions"} content={
                                [
                                    {
                                        heading:"Installation instructions for your current theme",
                                        details:currentSavedSettings.cartBlock? cartTheme2Content(): cartTheme1Content(),
                                    }
                                  
                                ]
                            }  disabled={currentSavedSettings.maxTrees==0} banner={budgetNotSetWarning} />
                                  </Card>
                          </Layout.AnnotatedSection>

                        <Layout.AnnotatedSection
                        title="Home Page Public Banner"
                            description={ 
                                <div>
                                    <p>This can be added to your home page and blog pages</p>
                                    <br/>
                                    <strong>Want a different language?</strong>
                                    <p>Please email us the details of the updated copy at saif@thegoodapi.com and we'll enable it for you.</p>
                                </div>}>
                          
                         
                        <Card>
                        <div style={{
                                "max-width": "100%",
                                "padding-top":"5%",
                                "padding-left":"5%",
                                "padding-right":"5%",
                            }}>
                            {treeCountBanner}
                            </div>
                            <br></br>
                            <Instructions disabled={currentSavedSettings.maxTrees==0} buttonLabel={"View Installation Instructions"} banner={treeCountBanner} content={[
                                {
                                    heading:"Installation instructions for your current theme",
                                    details:currentSavedSettings.counterBlock? bannerContentBlock(): bannerContent(),
                                },
                                {
                                    heading:"Specific blog post/page",
                                    details:bannerContentBlog(),
                                }
                            ]} />
                    </Card>
                        </Layout.AnnotatedSection>
                      
                        </Layout.Section>
                        {toastMarkup}
                    </Layout >
                    </div>
            
        </div>
    )
}