import { LegacyCard, Checkbox,Layout, Frame, Toast } from "@shopify/polaris"
import { useCallback, useState } from "react"
import React from "react"
import { BASE_URL, HTTPPost, isProd } from "../backend"
import { authenticatedFetch,} from '@shopify/app-bridge-utils';
import { useAppBridge, } from "@shopify/app-bridge-react"


export default function CustomerPlantingSection({enabled, initCheckboxState, setCartBadge, selected, treesPerProd, valueThreshold, shopName, updateCartBadge, cartBadgeVal, setToast}) {
  
    console.log("Current Saved Settings:", initCheckboxState)
    const [savedCheck, setSavedCheck] = React.useState(initCheckboxState)
    const [checkbox, setCheckbox] = React.useState(initCheckboxState)
    const handleChange = useCallback((newChecked) => setCheckbox(newChecked), []);
    const [loading, setLoading] = useState(false)
    const shopify = useAppBridge()
    const httpFetch = isProd() ? authenticatedFetch(shopify) : fetch


    console.log("Incoming", initCheckboxState, "savedCheck", savedCheck, "current state", checkbox)

    React.useEffect( () => {
        async function n() {
        updateCartBadge(checkbox)
        }
        n()
    }, [checkbox])

    console.log("ENABLED VALUE:", enabled)
    if (!enabled) {
        return <div></div>
    }
    return (
        <Layout>
    <Layout.AnnotatedSection id="Select Tree Planting" title="Allow Customers to Plant Additional Trees"
     description={ 
        <div>
            <strong>Note</strong>
            <p>Enabling this will create a new product in your product catalog. </p>
            <br/>
            <p>To <strong>hide</strong> the product from showing up in the storefront, please follow the instructions <a href="https://www.loom.com/share/e489549b2e0f487f9fec243d3a8179aa" target="_blank">here</a></p>
        </div>}>


        <LegacyCard title="Enable customer tree planting" sectiond
            primaryFooterAction={{
              loading: function(){
                console.log("LOADING STATE:::", loading)
                return loading
              }(),
              
              content: function() {
                if (checkbox == savedCheck) {
                    return "Saved"
                } else {
                    return "Save Settings"
                }
              }(),
              disabled: function() {
                return checkbox == savedCheck
              }(),
              onAction: async function(){
                setLoading(true)
                setSavedCheck(checkbox)
                const uri = `${BASE_URL}/app/customer_planting`
                const resp = await HTTPPost(httpFetch,uri, {
                    "customerPlantingEnabled": checkbox
                })

                console.log("RESP",resp)
                setLoading(false)
                if (resp.status == 200) {
                        if (checkbox ) {
                            setToast("Customer Planting Enabled", true)
                        } else {
                            setToast("Customer Planting Disabled", true)
                        }
                } else {
                    setToast("Failed to save - Please try again", true)
                }

              }
            }}
        >   
        <br></br>
            <div style={{paddingLeft:"20px"}}> 
            <Checkbox
            checked={checkbox}
            label="Allow customers to pay $1.00 USD to plant 3 additional trees from the cart"
            onChange={handleChange}
            />
                  
                            
                           
                
     
            <br></br>
            <br></br>
            </div>

        </LegacyCard>
    </Layout.AnnotatedSection>

        </Layout>
    )
}