import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import Router from './Router';
import {onCLS, onFID, onLCP} from 'web-vitals';
import { TrackVitals } from '../backend';


function sendToAnalytics(metric) {
  try {
  TrackVitals(fetch, metric.name, metric.value)
  console.log("Sending to analytics", metric, metric.name, metric.value)
  } catch (e) {
    console.log("Failed to send to analytics", e)
  }
}



export default function EntryPoint() {
    const search = window.location.href;
    const params = new URLSearchParams(search);
    const host = params.get('host')

    const config = {
        apiKey: process.env.SHOPIFY_PUBLIC_API_KEY,
        host: host,
        forceRedirect: false,
      };
    
    onLCP(sendToAnalytics);
    
    return (
      <AppProvider>
      <Provider config={config}>
         <Router />
      </Provider>
      </AppProvider>
    )
}