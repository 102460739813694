{
  "name": "@shopify/app-bridge",
  "version": "3.7.9",
  "types": "index.d.ts",
  "main": "index.js",
  "unpkg": "umd/index.js",
  "jsdelivr": "umd/index.js",
  "files": [
    "/actions/",
    "/client/",
    "/umd/",
    "/utilities/",
    "/util/",
    "/validate/",
    "/development.d.ts",
    "/development.js",
    "/index.d.ts",
    "/index.js",
    "/MessageTransport.d.ts",
    "/MessageTransport.js",
    "/production.d.ts",
    "/production.js"
  ],
  "private": false,
  "publishConfig": {
    "access": "public",
    "@shopify:registry": "https://registry.npmjs.org"
  },
  "repository": "git@github.com:Shopify/app-bridge.git",
  "homepage": "https://shopify.dev/docs/api/app-bridge/previous-versions/app-bridge-from-npm/app-setup",
  "author": "Shopify Inc.",
  "license": "MIT",
  "scripts": {
    "build": "yarn build:tsc && yarn build:npm && yarn build:umd",
    "build:tsc": "NODE_ENV=production tsc",
    "build:umd": "NODE_ENV=production webpack -p",
    "build:npm": "shx cp -r ./npm/index.js ./index.js",
    "check": "tsc",
    "clean": "yarn clean:tsc && yarn clean:npm && yarn clean:umd",
    "clean:tsc": "NODE_ENV=production tsc --build --clean",
    "clean:umd": "rm -rf ./umd",
    "clean:npm": "rm -rf ./index.js",
    "pack": "yarn pack",
    "size": "size-limit"
  },
  "sideEffects": false,
  "size-limit": [
    {
      "limit": "10.5 KB",
      "path": "production.js"
    }
  ],
  "dependencies": {
    "@shopify/app-bridge-core": "1.0.2",
    "base64url": "^3.0.1",
    "web-vitals": "^3.0.1"
  },
  "devDependencies": {
    "@types/node": "^10.12.5",
    "shx": "^0.3.3"
  }
}
