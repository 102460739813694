var baseURL = process.env.BACKEND_URL;
export var BASE_URL = process.env.BACKEND_URL;
import { useEffect, useRef } from 'react'


export function isProd() {
    return true
}



export function useInterval(callback, delay) {
   const savedCallback = useRef()
   useEffect(() => {
       savedCallback.current = callback
   }, [callback])

   useEffect(() => {
       function tick() {
           savedCallback.current();
       }

       if (delay != null) {
           const id = setInterval(tick, delay)
           return () => {
               clearInterval(id)
           }
       }
   }, [callback, delay])
}


export async function GetTopupState(httpFetch) {
    var resp = await httpFetch(`${baseURL}/app/additional_tree_state`)
    if (!resp.ok) {
        return {"status": "declined"}
    } 

    if (resp.status == 204) {
        return {"status": "noop"}
    }

    return await resp.json()
}

export async function PostAdditionalTrees(httpFetch, numTrees) {
    var resp = await httpFetch(`${baseURL}/app/plant_additional_trees`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"num_trees": numTrees})
    })

    if (!resp.ok) {
        console.log("failed to add trees")
        return false
    }

    var respJSON = await resp.json()
    return respJSON["confirmUrl"]
}

export async function GetShopAllowed(httpFetch) {
    var resp = await httpFetch(`${baseURL}/app/allow`)
    return resp.ok
}

export async function GetShopName(httpFetch) {
   var resp = await httpFetch(`${baseURL}/app/shop_name`)
   if (!resp.ok) {
      console.log("unknown shop name")
      return "unknown shop name"
   }  

   const shopName = await resp.text()
   return shopName
}

export async function TrackPage(httpFetch, pageName) {
   // make it a GET request 
    var resp = await httpFetch(`${baseURL}/app/track_page?page=${pageName}`)
    if (!resp.ok) {
        console.log("failed to track page")
        return false
    }
    return true
}

export async function TrackVitals(httpFetch, metricName, metricValue) {
    // make it a GET request 
     var resp = await httpFetch(`${baseURL}/app/track_vitals?${metricName}=${metricValue}`)
     if (!resp.ok) {
         console.log("failed to track page")
         return false
     }
     return true
 }
export async function GetUpdateURLIfAvailable(httpFetch) {
    var resp = await httpFetch(`${baseURL}/app/update`)
    if (!resp.ok) {
        console.log("failed to get update url")
        return ""
    }

    const updateURL = await resp.text()
    return updateURL
}

export async function GetShopSettings(httpFetch) { 
   var resp = await httpFetch(`${baseURL}/app/settings`)
   if (!resp.ok) {
         console.log("Error getting shop settings")
         return {"error": "Error getting shop settings"}
   }

   return resp.json()
}

export async function HTTPPost(httpFetch, uri, body) {
   const  requestOptions = {
        crossDomain: true,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
    var resp = await httpFetch(uri,requestOptions)
    return resp
}


export async function HTTPGET(httpFetch, uri) {
   const  requestOptions = {
        crossDomain: true,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    var resp = await httpFetch(uri,requestOptions)
    return resp
}

